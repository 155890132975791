import { Box, Hidden, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components';
import { Routes, urls } from '@/constants';
import { selectSessionUserId } from '@/store';
import { getRedirectSearchParams, logout } from '@/utils';

import { ProfileMenu } from './components';

export const NavbarActions = () => {
  const navigate = useNavigate();
  const userId = useSelector(selectSessionUserId);

  const isLgUp = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const textColorOverride = isLgUp ? null : 'white';

  if (userId) {
    return (
      <Box>
        <ProfileMenu logout={logout} />
      </Box>
    );
  }

  return (
    <Hidden mdDown>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button
          label="About Us"
          variant="tertiary"
          fullWidth={false}
          colorOverride={textColorOverride}
          onClick={() => window.open(urls.whatsLumiq, '_blank')}
        />
        <Button
          label="Log In"
          variant="tertiary"
          fullWidth={false}
          colorOverride={textColorOverride}
          onClick={() =>
            navigate({
              pathname: Routes.LOGIN,
              search: getRedirectSearchParams(),
            })
          }
        />
        <Button
          label="Sign Up"
          variant="tertiary"
          fullWidth={false}
          colorOverride={textColorOverride}
          onClick={() =>
            navigate({
              pathname: Routes.REGISTER,
              search: getRedirectSearchParams(),
            })
          }
        />
      </Box>
    </Hidden>
  );
};
