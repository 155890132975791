import { CheckCircleOutline, Close, Refresh } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Typography,
} from '@mui/material';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AnswerBox, Button } from '@/components';
import { EpisodeStatus } from '@/constants';
import { EpisodeCompleted } from '@/containers/EpisodeCompleted';
import { YourNextListen } from '@/containers/YourNextListen';
import { assessmentFail } from '@/images';
import { AnalyticsService, EVENT_CONSTANTS, EVENTS } from '@/services';
import {
  selectActiveModalParams,
  uiActions,
  useFetchEpisodeQuery,
  userEpisodesSelectors,
} from '@/store';
import { useFetchEpisodeAssessmentQuery } from '@/store/assessments-api';
import { useSubmitUserAssessmentMutation } from '@/store/user-assessments-api';

import { AssessmentQuestionSkeleton } from './AssessmentQuestionSkeleton';

export const AssessmentModalContent = () => {
  const dispatch = useDispatch();
  const { episodeId } = useSelector(selectActiveModalParams) || {};
  const { data: assessment, isLoading: isAssessmentLoading } =
    useFetchEpisodeAssessmentQuery({ episodeId }, { skip: !episodeId });

  const { data: episode } = useFetchEpisodeQuery(
    {
      episodeId,
      region: 'USA',
    },
    { skip: !episodeId },
  );
  const [answers, setAnswers] = useState([]);

  const [
    submitUserAssessment,
    { data: submitUserAssessmentResponse, reset, isLoading: isSubmitLoading },
  ] = useSubmitUserAssessmentMutation();

  const userEpisode = useSelector(state =>
    userEpisodesSelectors.selectById(state, episodeId),
  );

  const isCompleted = userEpisode?.status === EpisodeStatus.VERIFIED;

  useEffect(() => {
    if (assessment && assessment.questions?.length > 0) {
      setAnswers(assessment.questions.map(question => null));
    }
  }, [assessment]);

  if (!assessment) {
    return (
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    );
  }

  if (submitUserAssessmentResponse) {
    return (
      <DialogContent
        tabIndex={0}
        sx={{
          width: {
            xs: 'auto',
            md: 800,
            lg: 900,
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        {submitUserAssessmentResponse.pass ? (
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              my: 4,
              flexDirection: { sm: 'row', xs: 'column' },
            }}>
            <Close
              sx={{
                cursor: 'pointer',
                position: 'absolute',
                right: 0,
                top: 0,
                m: 1.5,
              }}
              onClick={() => dispatch(uiActions.closeActiveModal())}
            />
            <Box
              sx={{
                flexGrow: 1,
                alignContent: 'center',
                textAlign: 'center',
              }}>
              <EpisodeCompleted episodeId={episodeId} />
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="h1">Well done!</Typography>
                <Typography variant="body2">
                  You passed with a score of&nbsp;
                  {submitUserAssessmentResponse?.score}%
                </Typography>
                <Typography variant="body2">
                  You earned <b>{episode?.CPEDetails?.credits} credits</b>
                </Typography>
              </Box>
            </Box>
            <YourNextListen
              referrer={{ page: EVENT_CONSTANTS.PAGE.ASSESSMENT_COMPLETE }}
            />
          </Box>
        ) : (
          <Box style={{ textAlign: 'center', py: 4 }}>
            <Typography variant="h2" sx={{ fontWeight: 600 }}>
              Let&apos;s try one more time
            </Typography>
            <Typography variant="body2" mt={2}>
              You need at least {assessment.passRate}% to pass and you only got{' '}
              {submitUserAssessmentResponse?.score}%
            </Typography>
            <Box mt={2}>
              <img src={assessmentFail} alt="fail" style={{ maxWidth: 240 }} />
            </Box>
            <Typography variant="body2" sx={{ mt: 2, color: 'neutral35' }}>
              We know these exams can be hard sometimes, that's why we have
              unlimited tries!
            </Typography>
            <Box
              sx={{
                mt: 2,
                gap: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
              }}>
              <Button
                variant="tertiary"
                label="Close"
                fullWidth={false}
                skinny={false}
                onClick={() => {
                  dispatch(uiActions.closeActiveModal());
                  reset();
                }}
              />
              <Button
                variant="primary"
                label="Try Again"
                fullWidth={false}
                skinny={false}
                onClick={() => {
                  reset();
                  setAnswers(assessment.questions.map(question => null));
                }}
                startIcon={<Refresh />}
              />
            </Box>
          </Box>
        )}
      </DialogContent>
    );
  }

  return (
    <>
      <DialogContent
        tabIndex={0}
        sx={theme => ({
          maxWidth: { sm: theme.breakpoints.values.md },
          outlineColor: 'transparent',
        })}>
        <Box
          sx={{
            width: ({ dimensions }) => dimensions.episodeCardCover.width,
            height: ({ dimensions }) => dimensions.episodeCardCover.height,
            borderRadius: 4,
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            justifySelf: 'center',
            my: 2,
          }}>
          <Box
            component="img"
            id="episode-card-img"
            src={episode?.cover}
            alt="cover"
          />
        </Box>
        <Typography
          variant="h3"
          sx={{
            maxWidth: 300,
            display: 'flex',
            justifySelf: 'center',
            my: 2,
            textAlign: 'center',
          }}>
          {episode?.name}
        </Typography>
        {isCompleted ? (
          <Box
            sx={{
              mt: 2,
              borderRadius: 4,
              backgroundColor: 'green20',
              color: 'green',
              p: 2,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}>
            <CheckCircleOutline color="green" />
            <Typography variant="body2">
              This assessment has been completed.
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              mt: 2,
              borderRadius: 4,
              backgroundColor: 'blue10tint3',
              color: 'blue30',
              p: 2,
            }}>
            <Typography variant="body2">
              In accordance with{' '}
              {assessment.passRate === 70 ? 'NASBA' : 'State Level'} standards,
              participants are required to earn a minimum score of{' '}
              {assessment.passRate}% in order to pass the assessment.
            </Typography>
          </Box>
        )}
        {isAssessmentLoading ? (
          Array.from({ length: 4 }).map((_, idx) => (
            <Box key={idx} mb={2}>
              <AssessmentQuestionSkeleton />
            </Box>
          ))
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {assessment?.questions.map((question, index) => (
              <FormControl
                key={question.userAssessmentQuestionId}
                sx={{ mt: 4 }}>
                <FormLabel>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold', color: 'text.black', mb: 1 }}>
                    {index + 1}.&nbsp;&nbsp;&nbsp;{question.question}
                  </Typography>
                </FormLabel>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: { md: '1fr 1fr' },
                    gap: 1,
                    mt: 1,
                  }}>
                  {question.answers.map((answer, answerIndex) => (
                    <AnswerBox
                      key={`${index}-${answer.assessmentAnswerId}`}
                      onSelect={value => {
                        setAnswers(R.update(index, value, answers));
                      }}
                      isSelected={
                        isCompleted
                          ? answer.isCorrect
                          : answers[index] === answer.assessmentAnswerId
                      }
                      answer={answer}
                      index={answerIndex}
                      isDisabled={isSubmitLoading || isCompleted}
                      questionId={question.userAssessmentQuestionId}
                    />
                  ))}
                </Box>
              </FormControl>
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ borderTop: '1px solid', borderColor: 'neutral10' }}>
        <Button
          variant="tertiary"
          label="Close"
          fullWidth={false}
          onClick={() => {
            dispatch(uiActions.closeActiveModal());

            AnalyticsService.logEvent(EVENTS.ASSESSMENT_EVENTS.CLOSE, {
              episodeId,
              assessmentId: assessment.userAssessmentId,
              episodeName: episode?.name,
            });
          }}
        />
        {!isCompleted && (
          <Button
            variant="primary"
            label="Submit"
            fullWidth={false}
            disabled={answers.some(res => R.isNil(res)) || isSubmitLoading}
            onClick={async () => {
              const { data: result } = await submitUserAssessment({
                userAssessmentId: assessment.userAssessmentId,
                episodeId,
                answers,
                sessionId: userEpisode?.sessionId,
              });

              AnalyticsService.assessmentAttempt({
                episodeId,
                assessmentId: assessment.userAssessmentId,
                answer: answers,
                score: result?.score,
                pass: result?.pass,
              });

              if (result?.pass) {
                AnalyticsService.episodeComplete({
                  episodeId,
                });
              }
            }}
          />
        )}
      </DialogActions>
    </>
  );
};
