import { Box, IconButton, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { ChapterStatus } from '@/constants';

import { ChapterTileIcon } from './ChapterTileIcon';

export const EpisodeChapterTile = ({
  index,
  onIconClick,
  onTileClick,
  chapterType,
  chapterName,
  chapterStatus,
  chapterRemainingDuration,
  chapterProgressPercentage,
  isEpisodeRedeemRequired,
  isChapterPlaying,
  isChapterVerifiable,
  isPrimaryChapter,
}) => {
  const statusBarContents = getStatusBarContents({
    chapterStatus,
    chapterRemainingDuration,
    isChapterVerifiable,
  });

  const borderWidth =
    isPrimaryChapter || chapterStatus === ChapterStatus.QUIZ_READY ? 2 : 0.5;
  const borderColor =
    chapterStatus === ChapterStatus.VERIFIED ||
    chapterStatus === ChapterStatus.COMPLETED
      ? 'teal30'
      : 'blue30';

  const iconRef = useRef();
  const [isIconHovered, setIsIconHovered] = useState(false);

  useEffect(() => {
    const handleMouseOver = () => setIsIconHovered(true);
    const handleMouseOut = () => setIsIconHovered(false);

    const node = iconRef.current;
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);
      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, [iconRef]);

  return (
    <Box
      onClick={onTileClick}
      sx={{
        ...styles.chapterTile,
        border: borderWidth,
        borderColor,
      }}>
      <Box sx={styles.chapterDetails}>
        <Box sx={styles.titleContainer}>
          <Typography variant="subtitle1" sx={styles.titleText}>
            {index + 1}.
          </Typography>
          <Typography variant="subtitle1" sx={styles.titleText}>
            {chapterName}
          </Typography>
        </Box>
        <IconButton ref={iconRef} onClick={onIconClick}>
          <ChapterTileIcon
            index={index}
            chapterStatus={chapterStatus}
            chapterType={chapterType}
            isPrimaryChapter={isPrimaryChapter}
            isChapterPlaying={isChapterPlaying}
            isChapterVerifiable={isChapterVerifiable}
            isEpisodeRedeemRequired={isEpisodeRedeemRequired}
            isIconHovered={isIconHovered}
          />
        </IconButton>
      </Box>
      <Box
        sx={styles.statusBar}
        backgroundColor={statusBarContents.backgroundColor}>
        {chapterStatus === ChapterStatus.ON_GOING && (
          <Box
            sx={styles.progressBar}
            width={`${chapterProgressPercentage}%`}
          />
        )}
        <Typography
          variant="h6"
          sx={styles.statusBarText}
          color={statusBarContents.color}>
          {statusBarContents.text}
        </Typography>
      </Box>
    </Box>
  );
};

const getStatusBarContents = ({
  chapterStatus,
  chapterRemainingDuration,
  isChapterVerifiable,
}) => {
  switch (chapterStatus) {
    case ChapterStatus.COMPLETED:
    case ChapterStatus.VERIFIED:
      return {
        text: isChapterVerifiable ? 'Quiz completed' : 'Completed',
        color: 'neutral40',
        backgroundColor: 'teal10',
      };
    case ChapterStatus.QUIZ_READY:
      return {
        text: 'Quiz ready',
        color: 'white',
        backgroundColor: 'blue30',
      };
    case ChapterStatus.ON_GOING:
      return {
        text: `${chapterRemainingDuration} min${chapterRemainingDuration === 1 ? '' : 's'} remaining`,
        color: 'blue40',
        backgroundColor: 'neutral10',
      };
    default:
      return {
        text: `${chapterRemainingDuration} mins remaining`,
        color: 'blue40',
        backgroundColor: 'neutral10',
      };
  }
};

const styles = {
  chapterTile: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    backgroundColor: 'white',
    mt: 2.5,
    borderRadius: 2,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'neutral10tint1',
    },
  },
  chapterDetails: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    pl: 2,
    pr: 1,
    py: 3,
  },
  titleContainer: {
    display: 'flex',
  },
  titleText: {
    color: 'blue30',
    mr: 1,
  },
  statusBar: {
    position: 'relative',
    textAlign: 'center',
  },
  statusBarText: {
    position: 'relative',
    zIndex: 1,
  },
  progressBar: {
    position: 'absolute',
    backgroundColor: 'blue20',
    top: 0,
    bottom: 0,
    left: 0,
  },
};
