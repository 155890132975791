import { Box, Fade, Typography } from '@mui/material';

import { EpisodeRowMobile } from '../EpisodeRowMobile';

export const EpisodeTableMobile = ({ episodes, referrer }) => (
  <Box>
    {episodes?.length ? (
      episodes.map((episode, index) => (
        <Fade key={episode.episodeId} in timeout={Math.log10(index + 2) * 1000}>
          <Box>
            <EpisodeRowMobile
              episode={episode}
              index={index}
              referrer={{ ...referrer, position: index + 1 }}
            />
          </Box>
        </Fade>
      ))
    ) : (
      <Typography
        variant="subtitle2"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          pt: 2,
        }}>
        No episodes found.
      </Typography>
    )}
  </Box>
);
