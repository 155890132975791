import { createSelector } from '@reduxjs/toolkit';
import * as R from 'ramda';

import {
  LUMIQ_ORGANIZATION_NAME,
  LUMIQ_TEST_ORGANIZATION_NAME,
  PlanTypes,
} from '@/constants';
import { selectAppRegion, selectSessionUserId } from '@/store';
import { Plan } from '@/types';

import { RootState } from '../store';
import { userApiNode, userApiPostgrest } from './user-api.slice';

export const selectUserProfileResult =
  userApiPostgrest.endpoints.fetchUserProfile.select(undefined);

export const selectUserRegionResult =
  userApiNode.endpoints.fetchUserRegion.select(undefined);

export const selectUserRegion = (state: RootState) => {
  const { isLoading, data } = selectUserRegionResult(state);
  if (isLoading) return 'CAN';
  return data || selectAppRegion(state);
};

export const selectIsFreeTrialUser = (state: RootState) =>
  selectPlan(state)?.data?.freeTrial;

export const getDisablePurchasing = (state: RootState) => {
  const userId = selectSessionUserId(state);
  if (!userId) return false;

  const { data: plan } = selectPlan(state);
  if (!plan) return false;

  const isB2CUser = selectIsB2CUser(state);

  /* Disable purchasing if user has one of:
  - valid time plan
  - valid org usage plan
  */
  return (
    (plan.planType === PlanTypes.TIME &&
      plan.validUntil &&
      new Date(plan.validUntil) > new Date()) ||
    (plan.planType === PlanTypes.USAGE &&
      (R.isNil(plan.creditsRemaining) || plan.creditsRemaining > 0) &&
      !isB2CUser)
  );
};

export const selectPlan = userApiPostgrest.endpoints.fetchPlan.select();

export const selectIsB2CUser = createSelector(selectPlan, ({ data: plan }) => {
  return getIsB2CUser(plan);
});

export const getIsB2CUser = (plan?: Plan) =>
  !plan ||
  plan?.organizationName === LUMIQ_ORGANIZATION_NAME ||
  plan?.organizationName === LUMIQ_TEST_ORGANIZATION_NAME;
