import { createSvgIcon } from '@mui/material/utils';

export const LearningPathsActiveIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.1106 2.4748L2.47456 8.11084C0.326676 10.2587 0.326677 13.7411 2.47456 15.889L5.54812 18.9626L7.96515 17.7962L7.97743 17.7903L7.98988 17.7847L8.94798 17.3542C9.70091 17.0158 9.79727 15.9856 9.12013 15.5135C7.12857 14.1251 7.46734 11.082 9.7155 10.1655L12.7875 8.91319L11.6794 8.34827C11.1874 8.09741 10.9919 7.49519 11.2427 7.00316C11.4936 6.51114 12.0958 6.31563 12.5878 6.56648L15.6421 8.12365L16.4131 8.5167L16.1348 9.33611L15.0273 12.5973C14.8497 13.1203 14.2818 13.4002 13.7588 13.2227C13.2359 13.0451 12.9559 12.4772 13.1335 11.9542L13.5364 10.7677L10.4705 12.0175C9.69055 12.3355 9.57302 13.3912 10.2639 13.8729C12.2158 15.2337 11.938 18.2032 9.76773 19.1784L8.82206 19.6034L7.04599 20.4604L8.1106 21.5251C10.2585 23.6729 13.7409 23.6729 15.8888 21.5251L21.5248 15.889C23.6727 13.7411 23.6727 10.2587 21.5248 8.11084L15.8888 2.4748C13.7409 0.326919 10.2585 0.326922 8.1106 2.4748Z"
      fill="currentColor"
    />
  </svg>,
  'LearningPathsActive',
);
