export const PlayerErrorMessages = {
  BROWSER_AUTOPLAY_PROHIBITED: [
    'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission',
    'play() can only be initiated by a user gesture',
  ],
} as const;

export const PlayerEvents = {
  PLAY: 'content_play',
  PAUSE: 'content_pause',
  SEEK_BACK: 'content_seek_back',
  SEEK_FORWARD: 'content_seek_forward',
} as const;

export type SeekPlayerEvent =
  | typeof PlayerEvents.SEEK_BACK
  | typeof PlayerEvents.SEEK_FORWARD;

export const ListenRejectedReason = {
  NOT_LOGGED_IN: 'NOT_LOGGED_IN',
  PREMIUM_LOCKED: 'PREMIUM_LOCKED',
  ETHICS_LOCKED: 'ETHICS_LOCKED',
  NEED_REDEEM: 'NEED_REDEEM',
  NO_ACCESS_TYPE: 'NO_ACCESS_TYPE',
  PLAN_EXPIRED: 'PLAN_EXPIRED',
  PLAN_USED_UP: 'PLAN_USED_UP',
  SUBSCRIPTION_EXPIRED: 'SUBSCRIPTION_EXPIRED',
  MINUTES_USED_UP: 'MINUTES_USED_UP',
  FREE_TRIAL_ENDED: 'FREE_TRIAL_ENDED',
  UNKNOW_ACCESS_TYPE: 'UNKNOW_ACCESS_TYPE',
  CONTENT_NOT_NASBAIFIED: 'CONTENT_NOT_NASBAIFIED',
} as const;

export const CPDPlayerListenRejectedReasons = {
  ETHICS_LOCKED: 'lumiq.cpd.auth.restricted_content.ethics',
  PREMIUM_LOCKED: 'lumiq.cpd.auth.restricted_content.premium',
  INVALID_LICENCE: 'lumiq.cpd.auth.invalid_licence',
  INVALID_PLAN_TYPE: 'lumiq.cpd.auth.invalid_plan_type',
  UNKNOWN_EVENT: 'lumiq.cpd.auth.unknown_event',
  CHAPTER_NOT_SPECIFIED: 'lumiq.cpd.auth.chapter_not_specified',
  ALREADY_CLAIMED: 'lumiq.cpd.auth.already_claimed',
} as const;

export const CPEPlayerListenRejectedReasons = {
  ETHICS_LOCKED: 'lumiq.cpe.auth.restricted_content.ethics',
  PREMIUM_LOCKED: 'lumiq.cpe.auth.restricted_content.premium',
  INVALID_LICENCE: 'lumiq.cpe.auth.invalid_licence',
  INVALID_PLAN_TYPE: 'lumiq.cpe.auth.invalid_plan_type',
  UNKNOWN_EVENT: 'lumiq.cpe.auth.unknown_event',
  CHAPTER_NOT_SPECIFIED: 'lumiq.cpe.auth.chapter_not_specified',
  ALREADY_CLAIMED: 'lumiq.cpe.auth.already_claimed',
  NEED_REDEEM: 'lumiq.cpe.auth.requires_claim',
} as const;

export const PlayerConfigs = {
  SESSION_MODEL: 'session_model',
  LEGACY: 'legacy',
} as const;

export const SessionTypes = {
  CPD: 'cpd_fsm',
  CPE: 'cpe_fsm',
} as const;

export const PLAYER_FORWARD_30 = 30;
export const PLAYER_REPLAY_30 = -30;
export const PLAYER_START_TIME = 0;
export const DEFAULT_AUTO_SAVE_INTERVAL = 5;
export const DEFAULT_PLAYBACK_SPEED_OPTIONS = [1];
export const SAVE_INTERVAL_POSTGREST = 10;
