import { Routes, UnauthenticatedRoutes, urls } from '@/constants';
import { log } from '@/services';

export const createRouteWithParams = (route: string, params: object) => {
  let result = route;
  Object.entries(params).forEach(([key, value]) => {
    result = result.replace(`:${key}`, value);
  });
  return result;
};

export const getIsBranchLink = (search?: string) => {
  return !!new URLSearchParams(search).get('_branch_match_id');
};

export const getUtmParameters = (search?: string) => {
  return Array.from(new URLSearchParams(search).entries()).reduce(
    (obj, [param, value]) =>
      param.startsWith('utm_') ? { ...obj, [param]: value } : obj,
    {},
  );
};

export const getRedirectSearchParams = () => {
  const { origin, pathname, search } = window.location;
  const searchParams = new URLSearchParams(search);
  searchParams.set('redirect', `${origin}${pathname}`);
  return searchParams.toString();
};

export const parseRedirectUrl = () => {
  const searchParams = Object.fromEntries(
    new URLSearchParams(window.location.search),
  );

  let redirectUrl;
  try {
    // Check for luminariRedirect for backwards compatibility
    redirectUrl = new URL(
      searchParams.redirect ||
        searchParams.luminariRedirect ||
        (window.location.origin === urls.appBase &&
        !Object.values(UnauthenticatedRoutes).includes(window.location.pathname)
          ? window.location.href
          : null) ||
        `${urls.appBase}${Routes.DISCOVER}`,
    );
  } catch (error) {
    // If URL is invalid, fall back to discover
    log(error);
    redirectUrl = new URL(`${urls.appBase}${Routes.DISCOVER}`);
  }

  delete searchParams.redirect;
  delete searchParams.luminariRedirect;

  const newSearchParams = {
    ...searchParams,
    ...Object.fromEntries(new URLSearchParams(redirectUrl.search)),
  };

  redirectUrl.search = new URLSearchParams(newSearchParams).toString();

  return redirectUrl.toString();
};

export const getOauthURL = (service: keyof typeof urls.auth) => {
  const redirectUrl = parseRedirectUrl();
  const params = { redirect: redirectUrl };

  return `${urls.auth[service]}?${new URLSearchParams(params).toString()}`;
};
