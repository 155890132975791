import { Box } from '@mui/material';
import { Fragment } from 'react';

import { Skeleton } from '@/components';

interface EpisodeCardSkeletonProps {
  includeText?: boolean;
}

export const EpisodeCardSkeleton = ({
  includeText = false,
}: EpisodeCardSkeletonProps) => (
  <Box>
    <Box
      sx={({ dimensions }) => ({
        width: dimensions.episodeCardCover.width,
        height: dimensions.episodeCardCover.width,
        mb: 1,
      })}>
      <Skeleton variant="rounded" />
    </Box>

    {includeText && (
      <Fragment>
        <Skeleton />
        <Skeleton width="80%" />
        <Skeleton width="80%" />
      </Fragment>
    )}
  </Box>
);
