import { LocalActivity } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { DurationPill } from '@/components';
import { selectUserRegion } from '@/store';

export const CreditsDurationInfo = ({ credits, duration, durationPlayed }) => {
  const userRegion = useSelector(selectUserRegion);

  if (userRegion !== 'USA' || durationPlayed > 0) {
    return <DurationPill duration={duration} longForm={false} />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 0.25,
        flexDirection: 'row',
        alignItems: 'center',
      }}>
      <LocalActivity sx={{ color: 'neutral30', width: 12, height: 12 }} />
      <Typography variant="subtitle3" color="neutral30">
        {credits} Credits
      </Typography>
    </Box>
  );
};
