import { Box, Typography } from '@mui/material';

import { EpisodeCountIcon } from '@/icons';

export const EpisodeCount = ({ episodeCount }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 0.25,
        flexDirection: 'row',
        alignItems: 'center',
      }}>
      <EpisodeCountIcon sx={{ color: 'neutral30', width: 12, height: 12 }} />
      <Typography variant="subtitle3" color="neutral30">
        {episodeCount} Episodes
      </Typography>
    </Box>
  );
};
