import { Slider } from '@mui/material';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AnalyticsService } from '@/services';
import {
  playerThunks,
  selectActiveChapterId,
  selectIsPlayerSeeking,
  selectIsTrackLoaded,
  selectPlayerProgress,
  selectPlayerSeekToPosition,
  selectUserRegion,
  useFetchChapterQuery,
} from '@/store';
import { isChapterCompleted } from '@/store/player/player.utils';

export const ProgressBar = ({ referrer }) => {
  const dispatch = useDispatch();
  const [sliderValue, setSliderValue] = useState(null);
  const [isSliding, setIsSliding] = useState(false);

  const region = useSelector(selectUserRegion);
  const activeChapterId = useSelector(selectActiveChapterId);

  const { data: chapter } = useFetchChapterQuery({
    chapterId: activeChapterId,
    region,
  });

  const location = referrer;
  const playerProgress = useSelector(selectPlayerProgress);
  const seekToPosition = useSelector(selectPlayerSeekToPosition);
  const isPlayerSeeking = useSelector(selectIsPlayerSeeking);
  const isTrackLoaded = useSelector(selectIsTrackLoaded);
  const isCompleted = isChapterCompleted({
    currentTime: playerProgress?.position || 0,
    duration: chapter.duration,
  });

  useEffect(() => {
    if (
      !isSliding &&
      !isPlayerSeeking &&
      !R.isNil(playerProgress.position) &&
      sliderValue !== playerProgress.position
    ) {
      setSliderValue(playerProgress.position);
    }
  }, [isPlayerSeeking, playerProgress, sliderValue, isSliding]);

  const onSlide = (e, val) => {
    setSliderValue(val);
    setIsSliding(true);
  };

  const onSlidingComplete = (e, val) => {
    dispatch(playerThunks.seekToPosition({ position: val }));
    AnalyticsService.trackPlayerSeek({
      chapterId: activeChapterId,
      episodeId: chapter.episodeId,
      seekStart: playerProgress.position,
      seekLength: seekToPosition - playerProgress.position,
      alreadyListened: isCompleted,
      location,
    });
    setIsSliding(false);
  };

  const position =
    sliderValue ||
    (isPlayerSeeking ? seekToPosition : playerProgress.position) ||
    0;

  return (
    <Slider
      value={position}
      max={chapter.duration}
      onChange={onSlide}
      onChangeCommitted={onSlidingComplete}
      sx={{
        ...styles.root,
        '& .MuiSlider-thumb': styles.thumb,
        '& .MuiSlider-track': styles.track,
        '& .MuiSlider-rail': styles.rail,
      }}
      disabled={!isTrackLoaded}
    />
  );
};

const styles = {
  root: {
    display: 'flex',
    boxSizing: 'border-box',
    borderRadius: 0,
    p: 0,
    height: 4,
  },
  track: {
    color: 'aqua',
    height: 4,
  },
  rail: {
    color: 'white',
    height: 4,
  },
  thumb: {
    color: 'aqua',
    height: 12,
    width: 12,
  },
};
