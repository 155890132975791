import { emailRegex } from '@/constants';

import { RegisterFormState } from './types';

const isEmailValid = (form: RegisterFormState) =>
  emailRegex.test(form.email?.trim());
const isPasswordValid = (form: RegisterFormState) =>
  form.password?.trim().length > 0;
const isConfirmPasswordValid = (form: RegisterFormState) =>
  form.confirmPassword?.trim() === form.password?.trim();

export const FormConfig = {
  email: {
    validator: isEmailValid,
    errorMessage: 'Please enter a valid email address.',
  },
  password: {
    validator: isPasswordValid,
    errorMessage: 'Please enter a valid password.',
  },
  confirmPassword: {
    validator: isConfirmPasswordValid,
    errorMessage: 'Passwords do not match.',
  },
} as const;
