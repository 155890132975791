import {
  Box,
  TextField as TextFieldComponent,
  TextFieldProps,
  Typography,
} from '@mui/material';

import { theme } from '@/theme';

export const TextField = ({
  sx,
  label,
  type,
  onKeyUp,
  onEnterPress,
  ...props
}: TextFieldProps & { onEnterPress?: () => void }) => {
  return (
    <Box
      sx={{
        ...(Array.isArray(sx) ? Object.assign({}, ...sx) : sx),
      }}>
      <Typography variant="body3" sx={{ mb: 0.5 }}>
        {label}
      </Typography>
      <TextFieldComponent
        variant="outlined"
        hiddenLabel
        size="small"
        type={type}
        sx={{
          '& .MuiInputBase-input': {
            ...theme.typography.body2,
          },
        }}
        onKeyUp={e => {
          if (e.key === 'Enter') {
            onEnterPress?.();
          } else {
            onKeyUp?.(e);
          }
        }}
        {...props}
      />
    </Box>
  );
};
