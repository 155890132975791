import { createSvgIcon } from '@mui/material/utils';

export const DiscoverActiveIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM11.2443 9.258L16.4431 6.89487C16.8636 6.70369 17.2963 7.13635 17.1051 7.55694L14.7422 12.7557C14.342 13.6362 13.6363 14.3419 12.7558 14.7421L7.55699 17.1051C7.1364 17.2963 6.70373 16.8636 6.89491 16.443L9.25811 11.2442C9.65831 10.3638 10.3639 9.65819 11.2443 9.258ZM12.6294 12.7369C12.2389 13.1274 11.6057 13.1274 11.2152 12.7369C10.8247 12.3464 10.8247 11.7132 11.2152 11.3227C11.6057 10.9321 12.2389 10.9321 12.6294 11.3227C13.0199 11.7132 13.0199 12.3464 12.6294 12.7369Z"
      fill="currentColor"
    />
  </svg>,
  'DiscoverActive',
);
