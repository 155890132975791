import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Box, Fade, Grid, Typography } from '@mui/material';
import { useMemo, useState } from 'react';

import { AnalyticsService, EVENTS } from '@/services';

import { sortEpisodes } from '../../utils';
import { EpisodeRowDesktop } from '../EpisodeRowDesktop';

export const EpisodeTableDesktop = ({ episodes, referrer }) => {
  const [sortMethod, setSortMethod] = useState({
    column: 'subTypes',
    direction: 'asc',
  });

  const sortedEpisodes = useMemo(
    () => sortEpisodes({ episodes, sortMethod }),
    [episodes, sortMethod],
  );

  const changeSortMethod = column => {
    let direction = 'asc';
    if (sortMethod.column === column) {
      direction = sortMethod.direction === 'asc' ? 'desc' : 'asc';
    }
    setSortMethod({ column, direction });

    AnalyticsService.logEvent(EVENTS.TECHNICAL_SEARCH_EVENTS.SORT, {
      sortBy: column,
      order: direction,
    });
  };

  const SortIcon =
    sortMethod?.direction === 'asc' ? (
      <ArrowDropUp sx={styles.sortIcon} />
    ) : (
      <ArrowDropDown sx={styles.sortIcon} />
    );

  return (
    <Box sx={styles.episodeTableContainer}>
      <Grid container spacing={0} sx={styles.headerContainer}>
        <Grid item xs={8} sx={[styles.headerColumn, styles.firstColumn]}>
          <Box sx={styles.columnLabel} onClick={() => changeSortMethod('name')}>
            Title
            {sortMethod?.column === 'name' && SortIcon}
          </Box>
        </Grid>
        <Grid item xs={2} sx={styles.headerColumn}>
          <Box
            sx={styles.columnLabel}
            onClick={() => changeSortMethod('subTypes')}>
            Tag
            {sortMethod?.column === 'subTypes' && SortIcon}
          </Box>
        </Grid>
        <Grid item xs={2} sx={styles.headerColumn}>
          <Box
            sx={styles.columnLabel}
            onClick={() => changeSortMethod('regions')}>
            Region
            {sortMethod?.column === 'regions' && SortIcon}
          </Box>
        </Grid>
      </Grid>
      {sortedEpisodes?.length ? (
        sortedEpisodes.map((episode, index) => (
          <Fade
            key={episode.episodeId}
            in
            timeout={Math.log10(index + 2) * 1000}>
            <Box>
              <EpisodeRowDesktop
                episode={episode}
                index={index}
                referrer={{ ...referrer, position: index + 1 }}
              />
            </Box>
          </Fade>
        ))
      ) : (
        <Typography variant="subtitle2" sx={styles.emptyContainer}>
          No episodes found.
        </Typography>
      )}
    </Box>
  );
};

const styles = {
  episodeTableContainer: {
    border: theme => `1px solid ${theme.palette.neutral10}`,
    borderRadius: 1.5,
    overflow: 'hidden',
  },
  headerContainer: {
    height: '50px',
    backgroundColor: 'neutral10',
    p: 2,
  },
  headerColumn: {
    display: 'flex',
    alignItems: 'center',
    color: 'neutral35',
    justifyContent: 'center',
  },
  firstColumn: {
    justifyContent: 'flex-start',
    pl: 2,
  },
  columnLabel: theme => ({
    ...theme.typography.subtitle2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    px: 0.625,
    '&:hover': {
      textDecoration: 'underline',
    },
  }),
  sortIcon: {
    fontSize: 17,
    mr: -2,
  },
  emptyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    p: 2,
  },
};
