import { createSvgIcon } from '@mui/material/utils';

export const DownloadAppIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.6923 2H8.30769C7.03385 2 6 3.01818 6 4.27273V19.7273C6 20.9818 7.03385 22 8.30769 22H15.6923C16.9662 22 18 20.9818 18 19.7273V4.27273C18 3.01818 16.9662 2 15.6923 2ZM12 21.0909C11.2338 21.0909 10.6154 20.4818 10.6154 19.7273C10.6154 18.9727 11.2338 18.3636 12 18.3636C12.7662 18.3636 13.3846 18.9727 13.3846 19.7273C13.3846 20.4818 12.7662 21.0909 12 21.0909ZM16.1538 17.4545H7.84615V4.27273H12H16.1538V17.4545Z"
      fill="currentColor"
    />
  </svg>,
  'DownloadApp',
);
