import { ArrowForward } from '@mui/icons-material';
import { Box, Button, SxProps, Theme, Typography } from '@mui/material';

import { appleLogo, googleLogo } from '@/images';
import { getOauthURL } from '@/utils';

type OauthButtonProps = {
  type: 'google' | 'apple';
  sx?: SxProps<Theme>;
};

export const OauthButton = ({ type, sx }: OauthButtonProps) => {
  const oauthUrl = getOauthURL(type);
  return (
    <Button
      fullWidth
      sx={[styles.oauthButton, ...(Array.isArray(sx) ? sx : [sx])]}
      href={oauthUrl}>
      <Box sx={styles.oauthButtonLabel}>
        <Box
          component="img"
          alt={`${type} logo`}
          src={type === 'google' ? googleLogo : appleLogo}
          sx={{ height: 24 }}
        />
        <Typography variant="button2">
          Continue with {type === 'google' ? 'Google' : 'Apple'}
        </Typography>
      </Box>
      <ArrowForward
        sx={{
          fontSize: 24,
          color: 'blueBlack',
        }}
      />
    </Button>
  );
};

const styles = {
  oauthButton: {
    height: 48,
    fontSize: 16,
    backgroundColor: 'white',
    color: 'blueBlack',
    borderRadius: 1,
    border: (theme: Theme) => `1px solid ${theme.palette.neutral20}`,
    display: 'flex',
    justifyContent: 'space-between',
    p: 1.5,
  },
  oauthButtonLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
    gap: 2,
  },
};
