import {
  ArrowForward,
  ChevronLeft,
  ChevronRight,
  Close,
  PauseRounded,
  PlayArrowRounded,
  Refresh,
} from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import * as R from 'ramda';
import { useState } from 'react';

import { AnswerBox, Button, ButtonVariants } from '@/components';
import { EpisodeStatus } from '@/constants';
import { EpisodeCompleted } from '@/containers';
import { YourNextListen } from '@/containers/YourNextListen';
import { EVENT_CONSTANTS } from '@/services';
import { QuizAnswer } from '@/types';
import { convertSecondsToMinutes } from '@/utils';

import { ChapterQuizModalSkeleton } from './ChapterQuizModalSkeleton';
import { ChapterQuizModalContentProps } from './types';

export const ChapterQuizModalContentComponent = ({
  onNextPage,
  onPreviousPage,
  onSelectAnswer,
  onSubmitQuiz,
  onPlayChapter,
  onClose,
  showAssessment,
  pages,
  selectedPageIndex,
  selectedAnswerIndex,
  isChapterCompleted,
  isChapterPlaying,
  isQuizCompleted,
  isDataLoading,
  isSubmitLoading,
  region,
  episodeId,
  userEpisode,
}: ChapterQuizModalContentProps) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const selectedPage = !R.isNil(selectedPageIndex) && pages[selectedPageIndex];

  if (isDataLoading || !selectedPage)
    return (
      <Box sx={styles.modalContainer}>
        <ChapterQuizModalSkeleton />
      </Box>
    );

  const isNasba = region === 'USA';
  const { chapter, chapterIndex, quiz, quizCountLabel } = selectedPage;
  const chapterDuration = convertSecondsToMinutes(chapter.duration);
  const isCorrect = !!quiz?.answers[selectedAnswerIndex!]?.isCorrect;
  const quizzesComplete = pages.every(page => page.quiz?.completed);
  const episodeDuration = convertSecondsToMinutes(
    pages.reduce((total, page) => total + page.chapter.duration, 0),
  );

  if (isSubmitted && quizzesComplete) {
    const episodeComplete =
      !isNasba || userEpisode?.status === EpisodeStatus.VERIFIED;
    return (
      <Box sx={styles.modalContainer}>
        <Box sx={styles.headerContainer}>
          <Typography variant="h1" color="neutral40" sx={styles.headerLabel}>
            Quizzes Complete
          </Typography>
          <IconButton aria-label="close chapter modal" onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Box sx={styles.summaryContainer}>
          <Box sx={styles.summaryContent}>
            <EpisodeCompleted episodeId={episodeId} />
            {episodeComplete ? (
              <>
                <Typography variant="h1">Well done!</Typography>
                {!isNasba && (
                  <Typography variant="body1">
                    You&apos;ve earned {episodeDuration} minutes of CPD!
                  </Typography>
                )}
              </>
            ) : (
              <>
                <Typography variant="h1">You&apos;re almost done!</Typography>
                <Typography variant="body2" sx={styles.summaryAssessment}>
                  You&apos;ll need to complete the Qualified Assessment to earn
                  your CPE credits.
                </Typography>
              </>
            )}
          </Box>
          {episodeComplete && (
            <YourNextListen
              referrer={{ page: EVENT_CONSTANTS.PAGE.QUIZ_COMPLETE }}
            />
          )}
        </Box>

        {!episodeComplete && (
          <Box sx={styles.summaryComplete}>
            <Button
              onClick={onClose}
              label="Later"
              variant="tertiary"
              fullWidth={false}
              skinny={false}
            />
            <Button
              onClick={showAssessment}
              label="Take Assessment"
              fullWidth={false}
              skinny={false}
            />
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box sx={styles.modalContainer}>
      <Box>
        <Box sx={styles.headerContainer}>
          <Box sx={styles.headerLabel}>
            <Typography variant="h1" color="neutral40">
              Chapter {chapterIndex + 1}
            </Typography>
            <IconButton
              onClick={onPlayChapter}
              aria-label="play chapter"
              size="small"
              sx={styles.playButton}>
              {isChapterPlaying ? <PauseRounded /> : <PlayArrowRounded />}
            </IconButton>
          </Box>
          <Box sx={styles.headerActions}>
            <IconButton
              aria-label="previous quiz"
              onClick={() => {
                setIsSubmitted(false);
                onPreviousPage();
              }}
              sx={[styles.headerAction, styles.headerActionResponsive]}
              disabled={selectedPageIndex === 0}>
              <ChevronLeft />
            </IconButton>
            <IconButton
              aria-label="next quiz"
              onClick={() => {
                setIsSubmitted(false);
                onNextPage();
              }}
              sx={[styles.headerAction, styles.headerActionResponsive]}
              disabled={selectedPageIndex === pages.length - 1}>
              <ChevronRight />
            </IconButton>
            <IconButton aria-label="close chapter modal" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </Box>
        <Typography variant="h3" color="neutral35">
          {`${chapterDuration} min${chapterDuration > 1 ? 's' : ''}`}
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" color="neutral40">
          {chapter.name}
        </Typography>
        <Typography variant="body2" color="neutral50">
          {chapter.description}
        </Typography>
      </Box>
      {!!quiz && (
        <>
          <Box>
            <Typography variant="h2" color="neutral40">
              {!quizCountLabel ? 'Quiz' : `Quizzes (${quizCountLabel})`}
            </Typography>
            {isChapterCompleted ? (
              <Typography variant="body2" color="neutral50">
                {quiz.question}
              </Typography>
            ) : (
              <Box sx={styles.pendingQuizContainer}>
                <Typography variant="body2" color="neutral50">
                  Available when chapter is completed
                </Typography>
              </Box>
            )}
          </Box>
          {isChapterCompleted && (
            <Box>
              <Box sx={styles.answerGrid}>
                {quiz.answers.map((answer: QuizAnswer, index: number) => (
                  <Box sx={styles.answerContainer} key={index}>
                    <AnswerBox
                      onSelect={() => {
                        setIsSubmitted(false);
                        onSelectAnswer(index);
                      }}
                      isSelected={
                        selectedAnswerIndex === index ||
                        (isQuizCompleted && answer.isCorrect)
                      }
                      answer={answer}
                      index={index}
                      questionId={quiz.quizId}
                      isDisabled={isQuizCompleted || isSubmitLoading}
                      isSubmitted={
                        isQuizCompleted ||
                        (isSubmitted && !isSubmitLoading && !isCorrect)
                      }
                      showCaption={isQuizCompleted && isNasba}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </>
      )}
      <Box
        sx={[
          styles.bottomRow,
          isCorrect && isQuizCompleted && styles.bottomRowSuccess,
          !isCorrect && isSubmitted && styles.bottomRowFailure,
        ]}>
        {isQuizCompleted ? (
          isCorrect ? (
            <>
              <Box sx={styles.bottomRowMessage}>
                <Typography variant="h4" color="neutral50">
                  Well done!
                </Typography>
                {!isNasba && (
                  <Typography variant="body2" color="neutral50">
                    You’ve earned {chapterDuration} minutes of CPD!
                  </Typography>
                )}
              </Box>
              <Button
                onClick={() => {
                  setIsSubmitted(false);
                  onNextPage();
                }}
                label="Continue"
                endIcon={<ArrowForward />}
                variant={ButtonVariants.SUCCESS}
                fullWidth={false}
                skinny={false}
              />
            </>
          ) : (
            <Button
              onClick={() => {
                setIsSubmitted(false);
                onNextPage();
              }}
              label="Continue"
              endIcon={<ArrowForward />}
              variant={ButtonVariants.PRIMARY}
              fullWidth={false}
              skinny={false}
              sx={
                selectedPageIndex === pages.length - 1
                  ? {
                      visibility: 'hidden',
                    }
                  : undefined
              }
            />
          )
        ) : isSubmitted && !isCorrect ? (
          <>
            <Box>
              <Typography variant="h4" color="neutral50">
                Sorry, wrong answer.
              </Typography>
              <Typography variant="body2" color="neutral50">
                Please try again
              </Typography>
            </Box>
            <Button
              onClick={() => {
                setIsSubmitted(false);
              }}
              label="Retry"
              variant={ButtonVariants.FAILURE}
              startIcon={<Refresh />}
              fullWidth={false}
              skinny={false}
            />
          </>
        ) : (
          <Button
            onClick={() => {
              setIsSubmitted(true);
              onSubmitQuiz();
            }}
            label="Submit"
            disabled={
              !isChapterCompleted ||
              R.isNil(selectedAnswerIndex) ||
              isSubmitLoading
            }
            variant={ButtonVariants.PRIMARY}
            fullWidth={false}
            skinny={false}
          />
        )}
      </Box>
    </Box>
  );
};

const styles = {
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerLabel: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  headerActions: {
    gap: 1,
    display: 'flex',
  },
  headerAction: {
    backgroundColor: 'neutral10',
    color: 'neutral50',
  },
  headerActionResponsive: {
    display: {
      xs: 'none',
      sm: 'flex',
    },
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: {
      xs: 'auto',
      md: 800,
      lg: 900,
    },
    gap: 2,
    pt: 4,
    px: {
      xs: 2,
      sm: 6,
    },
  },
  summaryContainer: {
    display: 'flex',
    gap: 2,
    my: 4,
    flexDirection: { sm: 'row', xs: 'column' },
  },
  summaryContent: {
    flexGrow: 1,
    alignContent: 'center',
    textAlign: 'center',
  },
  summaryAssessment: {
    textAlign: 'center',
    justifySelf: 'center',
    maxWidth: 350,
    my: 2,
  },
  summaryComplete: {
    display: 'flex',
    gap: 2,
    flexDirection: { sm: 'row', xs: 'column' },
    justifyContent: 'end',
    p: 2,
  },
  pendingQuizContainer: {
    height: 240,
    display: 'flex',
    border: '1px solid',
    borderColor: 'neutral20',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 3,
    mt: 2,
  },
  answerGrid: {
    display: 'grid',
    gridTemplateColumns: { md: '1fr 1fr' },
    gap: 2,
  },
  answerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  bottomRow: {
    display: 'flex',
    justifyContent: 'end',
    gap: 3,
    mx: {
      xs: -4,
      sm: -6,
    },
    py: 2,
    px: {
      xs: 4,
      sm: 6,
    },
  },
  bottomRowMessage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  bottomRowSuccess: {
    backgroundColor: 'green20',
  },
  bottomRowFailure: {
    backgroundColor: 'red10',
  },
  playButton: {
    border: 1.5,
    borderColor: 'blue30',
    color: 'blue30',
    ml: 2,
  },
};
