import { Box } from '@mui/material';

import { Skeleton } from '@/components';

import { LP_COVER_WIDTH_SMALL } from './LearningPathCard';

export const LearningPathCardSkeleton = () => (
  <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
    <Skeleton
      variant="circular"
      height={LP_COVER_WIDTH_SMALL}
      width={LP_COVER_WIDTH_SMALL}
    />
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}>
      <Skeleton variant="text" width={200} height={18} />
      <Skeleton variant="text" width={150} height={18} />
    </Box>
  </Box>
);
