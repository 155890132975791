import { Box, Hidden, Typography, useMediaQuery } from '@mui/material';

import { useAccessInfo } from '@/hooks';

export const NavBarRemainingUsage = () => {
  const navbarAccessTypeInfo = useAccessInfo();
  const isLgUp = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const textColor = isLgUp ? 'neutral40' : 'white';

  if (!navbarAccessTypeInfo) return null;
  const {
    showExpiry,
    showUsage,
    infoTitle,
    usageRemaining,
    usageUnits,
    expiryDate,
  } = navbarAccessTypeInfo;

  return (
    <Hidden mdDown>
      {showExpiry && (
        <Box>
          <Box sx={styles.topRow}>
            <Typography variant="caption" color={textColor}>
              Subscription Valid Until
            </Typography>
          </Box>
          <Typography variant="subtitle2" color={textColor}>
            {expiryDate}
          </Typography>
        </Box>
      )}
      {showUsage && (
        <Box sx={styles.labelContainer}>
          <Typography variant="body3" color={textColor}>
            {infoTitle}
          </Typography>
          <Typography variant="subtitle2" color={textColor}>
            {`${usageRemaining} ${usageUnits}`}
          </Typography>
        </Box>
      )}
    </Hidden>
  );
};

const styles = {
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  clickable: {
    cursor: 'pointer',
  },
};
