import { createSvgIcon } from '@mui/material/utils';

export const TechnicalIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      x="3"
      y="3"
      width="18"
      height="18"
      rx="3.5"
      stroke="currentColor"
      strokeWidth="2"
    />
    <rect x="7" y="11" width="2" height="7" rx="1" fill="currentColor" />
    <rect x="15" y="14" width="2" height="4" rx="1" fill="currentColor" />
    <rect x="11" y="7" width="2" height="11" rx="1" fill="currentColor" />
  </svg>,
  'Technical',
);
