import { Pill } from '@/components/Pill';

interface FilterLibraryPillProps {
  label: string;
  onDelete: () => void;
}

export const FilterLibraryPill = ({
  label,
  onDelete,
}: FilterLibraryPillProps) => {
  return (
    <Pill color="primary" size="small" label={label} onDelete={onDelete} />
  );
};
