import {
  AccountCircle as AccountCircleIcon,
  Email as EmailIcon,
  Login as LoginIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';
import {
  Box,
  Hidden,
  List,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Modals,
  Routes,
  RoutesConfig,
  RouteType,
  SidebarItems,
  urls,
} from '@/constants';
import {
  DiscoverActiveIcon,
  DiscoverIcon,
  DownloadAppIcon,
  LearningPathsActiveIcon,
  LearningPathsIcon,
  LibraryActiveIcon,
  LibraryIcon,
  MembersActiveIcon,
  MembersIcon,
  MyLumiQActiveIcon,
  MyLumiQIcon,
  TechnicalActiveIcon,
  TechnicalIcon,
  TrackerActiveIcon,
  TrackerIcon,
} from '@/icons';
import { AnalyticsService, BUTTON_NAME } from '@/services';
import {
  selectSessionUserId,
  selectUserProfileResult,
  uiActions,
} from '@/store';
import { getRedirectSearchParams, logout } from '@/utils';

const SidebarIcons = {
  [SidebarItems.LEARNING_PATHS.PATH]: <LearningPathsIcon />,
  [SidebarItems.TECHNICAL.PATH]: <TechnicalIcon />,
  [SidebarItems.DISCOVER.PATH]: <DiscoverIcon />,
  [SidebarItems.LIBRARY.PATH]: <LibraryIcon />,
  [SidebarItems.TRACKER.PATH]: <TrackerIcon />,
  [SidebarItems.MY_LUMIQ.PATH]: <MyLumiQIcon />,
};

const SidebarActiveIcons = {
  [SidebarItems.LEARNING_PATHS.PATH]: <LearningPathsActiveIcon />,
  [SidebarItems.TECHNICAL.PATH]: <TechnicalActiveIcon />,
  [SidebarItems.DISCOVER.PATH]: <DiscoverActiveIcon />,
  [SidebarItems.LIBRARY.PATH]: <LibraryActiveIcon />,
  [SidebarItems.TRACKER.PATH]: <TrackerActiveIcon />,
  [SidebarItems.MY_LUMIQ.PATH]: <MyLumiQActiveIcon />,
};

export const SidebarList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const userId = useSelector(selectSessionUserId);

  const onListItemClick = (item: RouteType) => {
    dispatch(uiActions.toggleSidebarDrawer());
    navigate(item.PATH);
  };

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const { data: profile } = useSelector(selectUserProfileResult);
  const isUserManagementAdmin = profile?.memberRoles?.some(
    r => r.roleId === 'member_admin',
  );

  return (
    <List
      sx={{
        pt: 0,
        pb: theme => `${theme.dimensions.miniPlayer.height}px`,
        color: 'text.white',
      }}>
      {Object.values(SidebarItems).map(item => {
        const isSelected = pathname === item.PATH;
        const Icon = (isSelected ? SidebarActiveIcons : SidebarIcons)[
          item.PATH
        ];

        return (
          <SidebarItem
            key={item.PATH}
            Icon={Icon}
            name={item.NAME}
            isSelected={isSelected}
            onClick={() => onListItemClick(item)}
          />
        );
      })}
      <Hidden mdUp>
        {userId ? (
          <>
            <SidebarItem
              Icon={<AccountCircleIcon />}
              name={RoutesConfig.PROFILE.NAME}
              isSelected={pathname === RoutesConfig.PROFILE.PATH}
              onClick={() => {
                onListItemClick(RoutesConfig.PROFILE);
              }}
            />
            <SidebarItem
              Icon={<LogoutIcon />}
              name="Log Out"
              onClick={logout}
            />
          </>
        ) : (
          <SidebarItem
            Icon={<LoginIcon />}
            name="Log In"
            onClick={() =>
              navigate({
                pathname: Routes.LOGIN,
                search: getRedirectSearchParams(),
              })
            }
          />
        )}
        <SidebarItem
          Icon={<EmailIcon />}
          name="Support"
          onClick={() => {
            dispatch(uiActions.toggleSidebarDrawer());
            window.Intercom?.('show');
          }}
        />
      </Hidden>
      <SidebarItem
        Icon={<DownloadAppIcon />}
        name="Download App"
        onClick={() => {
          AnalyticsService.buttonClick({ name: BUTTON_NAME.DOWNLOAD_APP });

          dispatch(uiActions.toggleSidebarDrawer());
          if (isMobile) {
            window.open(urls.downloadApp);
            return;
          }

          dispatch(uiActions.setActiveModal({ name: Modals.APP_DOWNLOAD }));
        }}
      />

      {isUserManagementAdmin && (
        <>
          <Typography
            variant="body2"
            textTransform="uppercase"
            fontWeight="bold"
            mt={2}
            py={2}
            pl={3}>
            Admin Tools
          </Typography>
          <SidebarItem
            Icon={
              pathname === RoutesConfig.MEMBER_MANAGEMENT.PATH ? (
                <MembersActiveIcon />
              ) : (
                <MembersIcon />
              )
            }
            name="Members"
            isSelected={pathname === RoutesConfig.MEMBER_MANAGEMENT.PATH}
            onClick={() => onListItemClick(RoutesConfig.MEMBER_MANAGEMENT)}
          />
        </>
      )}
    </List>
  );
};

const SidebarItem = ({
  isSelected,
  Icon,
  onClick,
  name,
}: {
  isSelected?: boolean;
  Icon: any;
  onClick: any;
  name: string;
}) => (
  <Box
    onClick={onClick}
    sx={[
      {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        py: 2,
        pl: 3,
        cursor: 'pointer',
        '&:hover': {
          bgcolor: 'rgba(0, 0, 0, 0.3)',
        },
      },
      !!isSelected && {
        bgcolor: 'rgba(0, 0, 0, 0.2)',
      },
    ]}>
    <Box
      sx={{
        mr: 1,
        display: 'flex',
        alignItems: 'center',
        color: isSelected ? 'aqua' : 'text.white',
      }}>
      {Icon}
    </Box>
    <Box>
      <Typography variant="body2" sx={[{ color: 'white' }] as SxProps<Theme>}>
        {name}
      </Typography>
    </Box>
  </Box>
);
