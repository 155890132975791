import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';
import { Box, Theme, useMediaQuery, useTheme } from '@mui/material';
import { useRef } from 'react';
import { useSelector } from 'react-redux';

import { APP_LAYOUT_PX } from '@/containers/AppLayout';
import { EVENT_CONSTANTS } from '@/services';
import { selectUserRegion, useFetchFeaturedEpisodesQuery } from '@/store';

import { EpisodeBanner, EpisodeBannerSkeleton } from '../EpisodeBanner';

export const FeaturedEpisodesBannerSlide = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  const userRegion = useSelector(selectUserRegion);

  const {
    data: featuredEpisodes,
    isLoading,
    isError,
  } = useFetchFeaturedEpisodesQuery({
    region: userRegion,
  });

  const scrollAmount = useRef(0);

  const scrollTabs = (left: boolean) => {
    const bannerWidth =
      theme.dimensions.episodeBanner.width[isMobile ? 'xs' : 'md'];
    const scrollMax =
      (document.getElementById(containerId)?.lastChild as HTMLElement)
        ?.clientWidth - bannerWidth;

    document.getElementById(containerId)?.scrollTo({
      top: 0,
      left: (scrollAmount.current += left ? -bannerWidth : bannerWidth),
      behavior: 'smooth',
    });

    if (scrollAmount.current > scrollMax) {
      scrollAmount.current = scrollMax;
    } else if (scrollAmount.current < 0) {
      scrollAmount.current = 0;
    }
  };

  const containerId = 'Featured-Carousel';

  return (
    <>
      {isLoading && <EpisodeBannerSkeleton />}
      {!isLoading && isError && null}
      {!isLoading && !isError && (
        <Box sx={styles.root}>
          <Box sx={styles.scrollIconContainer}>
            <ChevronLeftRounded
              onClick={() => scrollTabs(true)}
              sx={[styles.scrollIcon, { ml: 1 }]}
            />
            <ChevronRightRounded
              onClick={() => scrollTabs(false)}
              sx={[styles.scrollIcon, { mr: 1 }]}
            />
          </Box>
          <Box
            id={containerId}
            sx={{
              overflowX: 'auto',
              px: APP_LAYOUT_PX,
              scrollbarWidth: 'none',
            }}>
            <Box sx={{ width: 'max-content', display: 'flex' }}>
              {featuredEpisodes?.map((episode, index) => (
                <Box
                  key={index}
                  sx={{ mr: index === featuredEpisodes.length - 1 ? 0 : 3 }}>
                  <EpisodeBanner
                    episode={episode}
                    referrer={{
                      page: EVENT_CONSTANTS.PAGE.DISCOVER,
                      component: EVENT_CONSTANTS.COMPONENT.FEATURED_BANNER,
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

const styles = {
  root: {
    display: 'flex',
    position: 'relative',
  },
  scrollIconContainer: {
    position: 'absolute',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
  },
  scrollIcon: {
    width: 36,
    height: 36,
    color: 'blue50',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '50%',
    cursor: 'pointer',
    pointerEvents: 'auto',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
    },
  },
};
