import { createSvgIcon } from '@mui/material/utils';

export const LearningPathsIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.18177 8.8179L8.8178 3.18186C10.5752 1.4245 13.4244 1.4245 15.1818 3.18186L20.8178 8.8179C22.5752 10.5753 22.5752 13.4245 20.8178 15.1819L15.1818 20.8179C13.4244 22.5753 10.5752 22.5753 8.81781 20.8179L3.18177 15.1819C1.42441 13.4245 1.42441 10.5753 3.18177 8.8179Z"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M15.1879 9.01449L12.1336 7.45732M15.1879 9.01449L14.0804 12.2757M15.1879 9.01449L10.0993 11.0889C8.58255 11.7072 8.35398 13.7603 9.69765 14.6971V14.6971C11.0121 15.6135 10.8284 17.6125 9.36886 18.2739L8.01937 18.8855"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>,
  'LearningPaths',
);
