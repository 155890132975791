import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modals } from '@/constants';
import { AnalyticsService } from '@/services';
import {
  selectSessionUserId,
  uiActions,
  useBookmarkEpisodeMutation,
  useFetchUserEpisodesQuery,
} from '@/store';
import { Location, Referrer } from '@/types';
import { SnackbarUtils } from '@/utils';

export const useBookmarkManagement = ({
  episodeId,
  referrer,
  location,
}: {
  episodeId: string;
  referrer?: Referrer;
  location?: Location;
}) => {
  const dispatch = useDispatch();
  const [bookmarkEpisode, { isLoading }] = useBookmarkEpisodeMutation();
  const userId = useSelector(selectSessionUserId);

  const [isBookmarkedOptimistic, setIsBookmarkedOptimistic] = useState(false);

  const { data } = useFetchUserEpisodesQuery(userId ? undefined : skipToken);

  const isBookmarked = data?.entities[episodeId]?.isBookmarked;

  useEffect(() => {
    if (isBookmarked !== undefined) {
      setIsBookmarkedOptimistic(isBookmarked);
    }
  }, [isBookmarked]);

  return {
    toggleBookmark: async (overrides = {}) => {
      if (!userId) {
        dispatch(uiActions.setActiveModal({ name: Modals.SIGN_UP }));
        return;
      }

      setIsBookmarkedOptimistic(prev => !prev);

      const params = {
        episodeId,
        bookmarked: !isBookmarked,
        ...overrides,
      };
      const bookmarkEpisodeResponse = await bookmarkEpisode(params);

      AnalyticsService.bookmark({
        episodeId,
        value: params.bookmarked,
        location,
        referrer,
      });

      if ('error' in bookmarkEpisodeResponse) {
        SnackbarUtils.error('There was a problem updating the bookmark');
        setIsBookmarkedOptimistic(!!isBookmarked);
      } else {
        SnackbarUtils.success(
          params.bookmarked ? 'Episode bookmarked' : 'Episode bookmark removed',
        );
      }
    },
    isBookmarking: isLoading,
    isBookmarked: isBookmarkedOptimistic,
  };
};
