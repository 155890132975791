import { Box, CircularProgress, Link, Typography } from '@mui/material';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Button } from '@/components';
import { Routes } from '@/constants';
import {
  useGetInviteDetailsQuery,
  useLazyGetSSORedirectUriQuery,
  useRegisterMutation,
} from '@/store';

import { FormContainer, OauthButton, TextField } from '../components';
import { RegisterErrorTypes } from './constants';
import { RegisterFormErrorState, RegisterFormState } from './types';
import { FormConfig } from './utils';

type RegisterFormProps = {
  skipToRegisterStep?: boolean;
  onLoginClick?: () => void;
  onRegisterSuccess?: () => void;
  onModalClose?: () => void;
};

export const RegisterForm = ({
  skipToRegisterStep,
  onLoginClick,
  onRegisterSuccess,
  onModalClose,
}: RegisterFormProps) => {
  const location = useLocation();
  const { inviteToken } = Object.fromEntries(
    new URLSearchParams(location.search),
  );
  const [form, setForm] = useState<RegisterFormState>({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState<RegisterFormErrorState>({
    email: undefined,
    password: undefined,
    confirmPassword: undefined,
  });
  const [isOnRegisterStep, setIsOnRegisterStep] = useState(false);

  const [register, { isLoading: isRegisterLoading }] = useRegisterMutation();

  const { data: inviteDetails, isLoading: isInviteDetailsLoading } =
    useGetInviteDetailsQuery(
      {
        inviteToken: inviteToken || '',
      },
      {
        skip: !inviteToken,
      },
    );
  const [trigger, { isLoading: isSSORedirectLoading }] =
    useLazyGetSSORedirectUriQuery();

  useEffect(() => {
    if (skipToRegisterStep) {
      setIsOnRegisterStep(true);
    }
  }, [skipToRegisterStep]);

  useEffect(() => {
    if (!inviteDetails) return;
    setForm({
      email: inviteDetails.email,
      password: '',
      confirmPassword: '',
    });
  }, [inviteDetails]);

  const onChange = ({
    target: { id, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setForm(prevState => ({
      ...prevState,
      [id]: value,
    }));
    setErrors(prevState => ({
      ...prevState,
      [id]: null,
    }));
  };

  const onContinueToRegister = async () => {
    if (!FormConfig.email.validator(form)) {
      setErrors({
        email: FormConfig.email.errorMessage,
      });
      return;
    }
    const res = await trigger({
      emailDomain: form.email.split('@')[1],
    });
    if (res.data) {
      window.location.href = res.data;
    } else {
      setIsOnRegisterStep(true);
    }
  };

  const onSubmit = async () => {
    const newErrors: RegisterFormErrorState = {};
    R.pipe(
      R.keys<RegisterFormState>,
      R.forEach<keyof RegisterFormState>(id => {
        if (!FormConfig[id].validator(form)) {
          newErrors[id] = FormConfig[id].errorMessage;
        }
      }),
    )(form);
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) return;

    const { offerId, inviteToken } = Object.fromEntries(
      new URLSearchParams(location.search),
    );

    const { error: requestError } = (await register({
      email: form.email.trim(),
      password: form.password.trim(),
      offerId,
      inviteToken,
      searchParams: Object.fromEntries(new URLSearchParams(location.search)),
    })) as { error: FetchBaseQueryError };

    if (requestError) {
      const errorMessage = (requestError?.data as { message: string })?.message;
      const errorType = RegisterErrorTypes.find(
        item => item.response === errorMessage,
      );
      if (errorType) {
        setErrors({ [errorType.field]: errorType.message });
      } else {
        setErrors({
          password: 'Something went wrong. Please try again later.',
        });
      }
      return;
    }

    onRegisterSuccess?.();
  };

  return (
    <FormContainer onModalClose={onModalClose}>
      {isInviteDetailsLoading ? (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography variant="h1">Create an account</Typography>
          {inviteDetails && (
            <Typography variant="h4" sx={{ mt: 1 }}>
              Join your colleagues at {inviteDetails.organizationName}
            </Typography>
          )}
          <Typography variant="body2" sx={{ mt: 1 }}>
            Already have an account?{' '}
            <Link
              href={onLoginClick ? '#' : `${Routes.LOGIN}${location.search}`}
              onClick={onLoginClick}
              sx={styles.link}>
              Login
            </Link>
          </Typography>
          <TextField
            id="email"
            fullWidth
            label="Email"
            placeholder="Enter your email"
            type="email"
            sx={{ mt: 6 }}
            value={form.email}
            error={!!errors.email}
            helperText={errors.email}
            disabled={isOnRegisterStep && !skipToRegisterStep}
            onChange={onChange}
            onEnterPress={onSubmit}
          />
          {!isOnRegisterStep ? (
            <>
              <Button
                variant="primary"
                label="Continue with email"
                fullWidth
                sx={{ mt: 2, height: 48, fontSize: 16 }}
                onClick={onContinueToRegister}
                disabled={isSSORedirectLoading}
              />
              <OauthButton type="google" sx={{ mt: 6 }} />
              <OauthButton type="apple" sx={{ mt: 1.5 }} />
            </>
          ) : (
            <>
              <TextField
                id="password"
                fullWidth
                label="Create a Password"
                placeholder="Enter your password"
                type="password"
                sx={{ mt: 2 }}
                error={!!errors.password}
                helperText={errors.password}
                onChange={onChange}
                onEnterPress={onSubmit}
              />
              <TextField
                id="confirmPassword"
                fullWidth
                label="Confirm Password"
                placeholder="Re-enter your password"
                type="password"
                sx={{ mt: 2 }}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
                onChange={onChange}
                onEnterPress={onSubmit}
              />
              <Button
                variant="primary"
                label="Agree and Create Account"
                fullWidth
                sx={{ mt: 2, height: 48, fontSize: 16 }}
                onClick={onSubmit}
                disabled={isRegisterLoading}
              />
              <Typography
                variant="subtitle3"
                sx={{ mt: 2.5, color: 'neutral30' }}>
                By clicking &quot;agree and continue&quot;, I agree to LumiQ's
                Terms &amp; Conditions and acknowledges the Privacy Policy.
              </Typography>
              <Typography variant="body2" sx={{ mt: 2 }}>
                <Link
                  onClick={() => {
                    setIsOnRegisterStep(false);
                    setForm({
                      email: form.email,
                      password: '',
                      confirmPassword: '',
                    });
                    setErrors({
                      email: undefined,
                      password: undefined,
                      confirmPassword: undefined,
                    });
                  }}
                  sx={styles.link}>
                  Create account another way
                </Link>
              </Typography>
            </>
          )}
        </>
      )}
    </FormContainer>
  );
};

const styles = {
  link: {
    textDecoration: 'none',
    color: 'blue30',
    '&:hover': {
      cursor: 'pointer',
    },
  },
};
