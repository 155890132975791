import { PageContainer } from '../components';
import { LoginForm } from './LoginForm';

export const LoginPage = () => {
  return (
    <PageContainer>
      <LoginForm />
    </PageContainer>
  );
};
