import { Box, Hidden, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components';
import { Routes } from '@/constants';
import { useAccessInfo } from '@/hooks';

export const SubscriptionBanner = () => {
  const navigate = useNavigate();
  const navbarAccessTypeInfo = useAccessInfo();

  if (!navbarAccessTypeInfo) return null;

  const { showUpgradeBanner, usageRemaining, expiryDate } =
    navbarAccessTypeInfo;
  if (!showUpgradeBanner || usageRemaining > 0) return null;

  return (
    <Hidden mdDown>
      <Box sx={styles.container}>
        <Box sx={styles.messageContainer}>
          <Typography color="blueBlack" variant="h5">
            {new Date(expiryDate) < new Date()
              ? 'YOUR FREE TRIAL HAS ENDED'
              : 'YOUR FREE TRIAL IS ENDING SOON'}
          </Typography>
          <Typography color="blueBlack" variant="subtitle1">
            Upgrade now to continue enjoying LumiQ!
          </Typography>
        </Box>
        <Button
          variant="gradientPrimary"
          fullWidth={false}
          skinny={false}
          onClick={() => navigate(Routes.PRICING)}
          label="UPGRADE NOW!"
        />
      </Box>
    </Hidden>
  );
};

const styles = {
  container: theme => ({
    position: 'sticky',
    zIndex: theme.zIndex.drawer,
    top: ({ dimensions }) => theme.typography.pxToRem(dimensions.header.height),
    height: theme.typography.pxToRem(80),
    backgroundColor: 'teal10',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    px: 6,
    py: 1,
  }),
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
  },
};
