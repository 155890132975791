import { createSvgIcon } from '@mui/material/utils';

export const MyLumiQIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 5C6 3.89543 6.89543 3 8 3L16 3C17.1046 3 18 3.89543 18 5V20.4112C18 20.8474 17.4804 21.0744 17.1603 20.7781L12.4076 16.3774C12.1776 16.1645 11.8224 16.1645 11.5924 16.3774L6.8397 20.7781C6.51961 21.0744 6 20.8474 6 20.4112L6 5Z"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>,
  'MyLumiQ',
);
