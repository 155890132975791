import { CheckCircleOutline, Lock, Pause } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

import { EpisodeStatus } from '@/constants';
import { PlayArrowFilledIcon, PlayArrowOutlinedIcon } from '@/icons';
import { theme } from '@/theme';
import { EpisodeStatus as EpisodeStatusType } from '@/types';
import { convertSecondsToHHMMSS } from '@/utils';

const HEIGHT = 26;
const PROGRESS_BAR_BUFFER = 5;

type EpisodeToolbarComponentProps = {
  episodeStatus: EpisodeStatusType;
  onClick: (event: React.MouseEvent) => void;
  isTrailerActive: boolean;
  isEpisodePlaying: boolean;
  trailerDuration: number;
  episodeDuration: number;
  playerPosition: number;
  episodePlayedDuration: number;
  showPremium: boolean;
};

export const EpisodeToolbar = ({
  episodeStatus = EpisodeStatus.NONE,
  onClick,
  isTrailerActive,
  isEpisodePlaying,
  trailerDuration,
  episodeDuration,
  playerPosition,
  episodePlayedDuration,
  showPremium,
}: EpisodeToolbarComponentProps) => {
  const episodeDurationLeft = convertSecondsToHHMMSS(
    episodeDuration - episodePlayedDuration,
  );
  const trailerDurationLeft = convertSecondsToHHMMSS(
    trailerDuration - playerPosition,
  );

  const Icon = getIcon({
    episodeStatus,
    isTrailerActive,
    isEpisodePlaying,
    showPremium,
  });
  const percentage = getPercentage({
    episodeStatus,
    playerPosition,
    episodePlayedDuration,
    trailerDuration,
    episodeDuration,
    showPremium,
  });
  const label = getLabel({
    episodeStatus,
    isTrailerActive,
    trailerDurationLeft,
    episodeDurationLeft,
    showPremium,
  });
  const textColor = getTextColor(episodeStatus);
  const background = getBackgroundColor({ episodeStatus, showPremium });

  return (
    <Box sx={styles.root} onClick={onClick}>
      <Box
        sx={[
          styles.background,
          {
            width: `${percentage < 100 ? Math.min(100 - (percentage || 0) + PROGRESS_BAR_BUFFER, 100) : 0}%`,
          },
        ]}
      />
      <Box
        sx={[
          styles.progressBar,
          {
            width: `${percentage || 0}%`,
            ...background,
          },
        ]}
      />
      <Box sx={styles.barContents}>
        {!!Icon && <Icon sx={[styles.icon, { color: textColor }]} />}
        <Typography
          sx={{ color: textColor, textWrap: 'nowrap' }}
          variant="button3">
          {label}
        </Typography>
      </Box>
      <Box sx={styles.hoverOverlay} />
    </Box>
  );
};

const getPercentage = ({
  episodeStatus,
  playerPosition,
  episodePlayedDuration,
  trailerDuration,
  episodeDuration,
  showPremium,
}: {
  episodeStatus: EpisodeStatusType;
  playerPosition: number;
  episodePlayedDuration: number;
  trailerDuration: number;
  episodeDuration: number;
  showPremium: boolean;
}) => {
  if (episodeStatus === EpisodeStatus.NONE) {
    if (showPremium) return 100;
    return trailerDuration ? (playerPosition / trailerDuration) * 100 : 0;
  }
  if (episodeStatus === EpisodeStatus.ON_GOING) {
    return episodeDuration
      ? (episodePlayedDuration / episodeDuration) * 100
      : 0;
  }
  return 100;
};

const getIcon = ({
  episodeStatus,
  isTrailerActive,
  isEpisodePlaying,
  showPremium,
}: {
  episodeStatus: EpisodeStatusType;
  isTrailerActive: boolean;
  isEpisodePlaying: boolean;
  showPremium: boolean;
}) => {
  if (episodeStatus === EpisodeStatus.NONE) {
    if (showPremium) return Lock;
    return isTrailerActive && isEpisodePlaying ? Pause : PlayArrowOutlinedIcon;
  }
  if (episodeStatus === EpisodeStatus.ON_GOING) {
    return isEpisodePlaying ? Pause : PlayArrowFilledIcon;
  }
  if (episodeStatus === EpisodeStatus.VERIFIED) return CheckCircleOutline;
  return null;
};

const getLabel = ({
  episodeStatus,
  isTrailerActive,
  trailerDurationLeft,
  episodeDurationLeft,
  showPremium,
}: {
  episodeStatus: EpisodeStatusType;
  isTrailerActive: boolean;
  trailerDurationLeft: string;
  episodeDurationLeft: string;
  showPremium: boolean;
}) => {
  if (episodeStatus === EpisodeStatus.NONE) {
    if (showPremium) return 'Premium';
    return isTrailerActive ? `${trailerDurationLeft} left` : 'Preview';
  }
  if (episodeStatus === EpisodeStatus.ON_GOING) {
    return `${episodeDurationLeft} left`;
  }
  if (episodeStatus === EpisodeStatus.QUIZ_READY) return 'Quiz Ready';
  if (episodeStatus === EpisodeStatus.ASSESSMENT_READY) return 'Assessment';
  if (episodeStatus === EpisodeStatus.VERIFIED) return 'Completed';
  return null;
};

const getTextColor = (episodeStatus: EpisodeStatusType) => {
  return episodeStatus === EpisodeStatus.NONE ||
    episodeStatus === EpisodeStatus.ON_GOING
    ? 'blue50'
    : 'white';
};

const getBackgroundColor = ({
  episodeStatus,
  showPremium,
}: {
  episodeStatus: EpisodeStatusType;
  showPremium: boolean;
}) => {
  if (episodeStatus === EpisodeStatus.NONE) {
    if (showPremium) return { background: theme.gradients.gold };
    return { backgroundColor: 'blue20' };
  }
  if (episodeStatus === EpisodeStatus.ON_GOING) {
    return { backgroundColor: 'blue20' };
  }
  if (
    episodeStatus === EpisodeStatus.QUIZ_READY ||
    episodeStatus === EpisodeStatus.ASSESSMENT_READY
  ) {
    return { background: theme.gradients.warning };
  }
  return { background: theme.gradients.primary };
};

const styles = {
  root: {
    position: 'relative',
    display: 'flex',
    height: HEIGHT,
    borderRadius: HEIGHT / 2,
    flexDirection: 'row',
    overflow: 'hidden',
    px: 1.5,
    cursor: 'pointer',
  },
  background: {
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
  },
  progressBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
  },
  barContents: {
    display: 'flex',
    flexDirection: 'row',
    zIndex: 1,
    alignItems: 'center',
  },
  icon: {
    width: 14,
    height: 14,
    mr: 0.5,
  },
  hoverOverlay: {
    transition: 'opacity 0.15s',
    position: 'absolute',
    opacity: 0,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 2,
    backgroundColor: 'black',
    '&:hover': {
      opacity: 0.1,
    },
  },
};
