import { submitUserAssessment } from '@/requests';
import { AnalyticsService, EVENTS } from '@/services';

import { nodeApi } from '../node-api';
import { postgrestApi } from '../postgrest-api';
import { NodeApiTags, PostgrestApiTags } from '../store.constants';
import { handleOnQueryStartedError } from '../store.utils';

type SubmitUserAssessmentPayload = {
  episodeId: string;
  userAssessmentId: string;
  answers: number[];
  sessionId?: string;
};

type SubmitUserAssessmentResponse = {
  pass: boolean;
  score: number;
};

export const userAssessmentsApi = nodeApi.injectEndpoints({
  endpoints: builder => ({
    submitUserAssessment: builder.mutation<
      SubmitUserAssessmentResponse,
      SubmitUserAssessmentPayload
    >({
      query: submitUserAssessment,
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          AnalyticsService.logEvent(EVENTS.ASSESSMENT_EVENTS.SUBMIT.INTENT);
          const { data } = await queryFulfilled;
          if (data?.pass) {
            dispatch(nodeApi.util.invalidateTags([NodeApiTags.USER_EPISODES]));
            dispatch(
              postgrestApi.util.invalidateTags([
                PostgrestApiTags.PLAYER_SESSIONS,
              ]),
            );
          }
          AnalyticsService.logEvent(EVENTS.ASSESSMENT_EVENTS.SUBMIT.SUCCESS);
        } catch (error: any) {
          AnalyticsService.logEvent(EVENTS.ASSESSMENT_EVENTS.SUBMIT.FAIL, {
            error: error?.message,
          });
          handleOnQueryStartedError(error);
        }
      },
      transformResponse: (response: any) => ({
        ...response.data,
        score: Math.round(response.data.score * 100) / 100,
      }),
    }),
  }),
});

export const { useSubmitUserAssessmentMutation } = userAssessmentsApi;
