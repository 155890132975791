import { Box, Typography } from '@mui/material';
import { ReactNode, useState } from 'react';

import { NASBADisclaimerModal } from '@/components';
import {
  background,
  certificationCan,
  certificationUsa,
  lumiqWhiteLogo,
} from '@/images';

const CertificationInfo = () => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Box
      sx={{
        mt: 8,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1.25,
        maxWidth: 300,
      }}>
      <Box
        component={'img'}
        src={certificationUsa}
        sx={{ width: 36, cursor: 'pointer', '&:hover': { opacity: 0.6 } }}
        onClick={() => setOpen(true)}
      />
      <Box component={'img'} src={certificationCan} sx={{ width: 36 }} />
      <Typography variant="caption" sx={{ color: 'white', lineHeight: 1.5 }}>
        Verifiable under all Canadian CPD and NASBA CPE regulations
      </Typography>
      <NASBADisclaimerModal isOpen={open} onClose={() => setOpen(false)} />
    </Box>
  );
};

export const PageContainer = ({ children }: { children?: ReactNode }) => {
  return (
    <Box sx={styles.pageContainer}>
      <Box
        component={'img'}
        src={lumiqWhiteLogo}
        sx={{ width: 200, mb: 6, display: { xs: 'flex', md: 'none' } }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 6,
          width: '100%',
        }}>
        <Box
          sx={{
            display: {
              xs: 'none',
              md: 'flex',
            },
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
          <Box component="img" src={lumiqWhiteLogo} sx={{ width: 200 }} />
          <Typography variant="h2" sx={{ color: 'white', width: 300, mt: 2 }}>
            We make professional education enjoyable.
          </Typography>
          <CertificationInfo />
        </Box>
        {children}
      </Box>
      <Box
        sx={{
          display: {
            xs: 'block',
            md: 'none',
          },
        }}>
        <CertificationInfo />
      </Box>
    </Box>
  );
};

const styles = {
  pageContainer: {
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    px: 2,
    py: 6,
    alignItems: 'center',
    justifyContent: 'center',
    background: `url(${background}) center`,
    backgroundSize: 'cover',
  },
};
