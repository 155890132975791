import { Box, useTheme } from '@mui/material';
import { Link as RouterLink, To } from 'react-router-dom';

import { Variants } from './constants';

type LinkProps = {
  to?: To;
  state?: any;
  onClick?: () => void;
  children: React.ReactNode;
  variant?: (typeof Variants)[keyof typeof Variants];
  inline?: boolean;
  color?: string;
  target?: string;
};

export const Link = ({
  to,
  state,
  onClick = undefined,
  children,
  variant = Variants.CLASSIC,
  inline = false,
  color = undefined,
  ...props
}: LinkProps) => {
  const theme = useTheme();

  if (!onClick && !to) return <>{children}</>;

  return (
    <Box
      component={to ? RouterLink : 'div'}
      onClick={onClick}
      to={to}
      state={state}
      {...props}
      sx={[
        {
          ...theme.typography.body2,
          cursor: 'pointer',
          color: 'inherit',
          textDecoration: 'inherit',
          maxHeight: 'fit-content',
          maxWidth: 'fit-content',
        },
        variant === Variants.PLAIN && {
          color: 'inherit',
        },
        variant === Variants.CLASSIC && {
          color: 'blue30',
          ':hover': {
            textDecoration: 'underline',
          },
        },
        inline && {
          display: 'inline',
        },
        color && {
          color,
        },
      ]}>
      {children}
    </Box>
  );
};
