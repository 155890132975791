import { Skeleton } from '@/components';
import { APP_LAYOUT_PX } from '@/containers/AppLayout';

export const EpisodeBannerSkeleton = () => (
  <Skeleton
    variant="rounded"
    width="auto"
    sx={{
      height: theme => theme.dimensions.episodeBanner.height,
      mx: APP_LAYOUT_PX,
      borderRadius: 4,
    }}
  />
);
