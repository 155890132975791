import { Link, Theme, Typography } from '@mui/material';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import * as R from 'ramda';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Button } from '@/components';
import { Routes } from '@/constants';
import { useLazyGetSSORedirectUriQuery, useLoginMutation } from '@/store';

import { FormContainer, OauthButton, TextField } from '../components';
import { LoginFormErrorState, LoginFormState } from './types';
import { FormConfig } from './utils';

type LoginFormProps = {
  onSignUpClick?: () => void;
  onLoginSuccess?: () => void;
  onModalClose?: () => void;
};

export const LoginForm = ({
  onSignUpClick,
  onLoginSuccess,
  onModalClose,
}: LoginFormProps) => {
  const location = useLocation();

  const [form, setForm] = useState<LoginFormState>({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState<LoginFormErrorState>({
    email: undefined,
    password: undefined,
  });
  const [failedSubmitCount, setFailedSubmitCount] = useState(0);
  const [isOnLoginStep, setIsOnLoginStep] = useState(false);

  const [trigger, { isLoading: isSSORedirectLoading }] =
    useLazyGetSSORedirectUriQuery();
  const [login, { isLoading }] = useLoginMutation();

  const onChange = ({
    target: { id, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setForm(prevState => ({
      ...prevState,
      [id]: value,
    }));
    setErrors(prevState => ({
      ...prevState,
      [id]: null,
    }));
  };

  const onContinueToLogin = async () => {
    const isEmailValid = FormConfig.email.validator(form);
    if (!isEmailValid) {
      setErrors({
        email: FormConfig.email.errorMessage,
      });
      return;
    }
    const { data: redirectUri } = await trigger({
      emailDomain: form.email.split('@')[1],
    });
    if (redirectUri) {
      window.location.href = redirectUri;
    } else {
      setIsOnLoginStep(true);
    }
  };

  const onSubmit = async () => {
    const newErrors: LoginFormErrorState = {};
    R.pipe(
      R.keys<LoginFormState>,
      R.forEach<keyof LoginFormState>(id => {
        if (!FormConfig[id].validator(form)) {
          newErrors[id] = FormConfig[id].errorMessage;
        }
      }),
    )(form);
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) return;

    const { error: requestError } = (await login({
      email: form.email.trim(),
      password: form.password.trim(),
    })) as { error: FetchBaseQueryError };

    if (requestError) {
      setFailedSubmitCount(prevState => prevState + 1);
      setErrors({
        email:
          failedSubmitCount >= 2
            ? 'Try entering a different email, like your company or personal email.'
            : undefined,
        password:
          (requestError?.status === 400 &&
            (requestError?.data as { message: string })?.message) ||
          'Something went wrong. Please try again later.',
      });
      return;
    }

    setFailedSubmitCount(0);
    onLoginSuccess?.();
  };

  return (
    <FormContainer onModalClose={onModalClose}>
      <Typography variant="h1">Login</Typography>
      <Typography variant="body2" sx={{ mt: 1 }}>
        New user?{' '}
        <Link
          href={onSignUpClick ? '#' : `${Routes.REGISTER}${location.search}`}
          onClick={onSignUpClick}
          sx={styles.link}>
          Create an account
        </Link>
      </Typography>
      <TextField
        id="email"
        fullWidth
        placeholder="Enter your email"
        label="Email"
        onChange={onChange}
        error={!!errors.email}
        helperText={errors.email}
        sx={{
          mt: 6,
        }}
        disabled={isOnLoginStep}
        onEnterPress={isOnLoginStep ? onSubmit : onContinueToLogin}
      />
      {!isOnLoginStep && (
        <>
          <Button
            fullWidth
            label="Continue"
            disabled={isSSORedirectLoading}
            sx={{ height: 48, fontSize: 16, mt: 2 }}
            onClick={onContinueToLogin}
          />
          <OauthButton type="google" sx={{ mt: 6 }} />
          <OauthButton type="apple" sx={{ mt: 1.5 }} />
        </>
      )}
      {isOnLoginStep && (
        <>
          <TextField
            id="password"
            fullWidth
            label="Password"
            type="password"
            placeholder="Password"
            sx={{ mt: 2 }}
            error={!!errors.password}
            helperText={errors.password}
            onChange={onChange}
            onEnterPress={onSubmit}
          />
          <Typography variant="body2" sx={{ mt: 2 }}>
            <Link
              href={Routes.FORGOT_PASSWORD}
              sx={{
                textDecoration: 'none',
                color: 'blue30',
              }}>
              Forgot Password
            </Link>
          </Typography>
          <Button
            fullWidth
            disabled={isLoading}
            label="Login"
            sx={{ height: 48, fontSize: 16, mt: 2 }}
            onClick={onSubmit}
          />
          <Typography variant="body2" sx={{ mt: 2 }}>
            <Link
              onClick={() => {
                setIsOnLoginStep(false);
                setErrors({
                  email: form.email,
                  password: '',
                });
                setErrors({
                  email: undefined,
                  password: undefined,
                });
              }}
              sx={styles.link}>
              Log in to a different account
            </Link>
          </Typography>
        </>
      )}
    </FormContainer>
  );
};

const styles = {
  oauthButton: {
    height: 48,
    fontSize: 16,
    mt: 1.5,
    backgroundColor: 'white',
    color: 'blueBlack',
    borderRadius: 1,
    border: (theme: Theme) => `1px solid ${theme.palette.neutral20}`,
    display: 'flex',
    justifyContent: 'space-between',
    p: 1.5,
  },
  oauthButtonLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
    gap: 2,
  },
  link: {
    textDecoration: 'none',
    color: 'blue30',
    '&:hover': {
      cursor: 'pointer',
    },
  },
};
