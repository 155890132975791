import { Box, Theme } from '@mui/material';

import { ChapterStatus, EpisodeStatus } from '@/constants';

type StatusType =
  | (typeof EpisodeStatus)[keyof typeof ChapterStatus]
  | (typeof EpisodeStatus)[keyof typeof EpisodeStatus];

interface CircleStatusIconProps {
  status?: StatusType;
  sx?: { width: number };
}

export const CircleStatusIcon = ({
  status = EpisodeStatus.NONE,
  sx = { width: 10 },
}: CircleStatusIconProps) => (
  <Box
    sx={[
      styles.root,
      {
        width: sx?.width,
        height: sx.width,
      },
      Array<StatusType>(
        ChapterStatus.COMPLETED,
        ChapterStatus.VERIFIED,
        EpisodeStatus.COMPLETED,
      ).includes(status) && styles.fullGreen,
      Array<StatusType>(
        ChapterStatus.QUIZ_READY,
        EpisodeStatus.QUIZ_READY,
        EpisodeStatus.ASSESSMENT_READY,
      ).includes(status) && styles.fullBlue,
      Array<StatusType>(
        ChapterStatus.ON_GOING,
        EpisodeStatus.ON_GOING,
      ).includes(status) && styles.halfBlue,
    ]}
  />
);

const styles = {
  root: {
    borderRadius: '50%',
    backgroundColor: 'neutral10',
  },
  fullGreen: {
    backgroundColor: 'green',
  },
  fullBlue: {
    backgroundColor: 'blue30',
  },
  halfBlue: {
    backgroundImage: (theme: Theme) =>
      `linear-gradient(90deg, ${theme.palette.blue30} 50%, transparent 50%)`,
  },
};
