import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Modals } from '@/constants';
import { LoginForm, RegisterForm } from '@/containers';
import { uiActions } from '@/store';

import { AppModal } from '../AppModal';

export const SignupModal = () => {
  const dispatch = useDispatch();
  const [showLogin, setShowLogin] = useState(true);

  return (
    <AppModal
      modalName={Modals.SIGN_UP}
      disableBackdropClick
      PaperComponent={({ children }) => children}>
      {showLogin ? (
        <LoginForm
          onSignUpClick={() => setShowLogin(false)}
          onLoginSuccess={() => {
            window.location.reload();
          }}
          onModalClose={() => dispatch(uiActions.closeActiveModal())}
        />
      ) : (
        <RegisterForm
          onLoginClick={() => setShowLogin(true)}
          onRegisterSuccess={() => {
            window.location.reload();
          }}
          onModalClose={() => dispatch(uiActions.closeActiveModal())}
        />
      )}
    </AppModal>
  );
};
