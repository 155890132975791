import { Bookmark, BookmarkBorder } from '@mui/icons-material';
import { Box, Theme, Typography } from '@mui/material';
import { useState } from 'react';
import { InView, useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';

import { CardFlair, DurationPill, EthicsPill } from '@/components';
import { EpisodeStatus, Routes } from '@/constants';
import { EpisodeCardSkeleton, Link, LinkVariants } from '@/containers';
import { useBookmarkManagement } from '@/hooks';
import { AnalyticsService, EVENT_CONSTANTS } from '@/services';
import {
  getIsEpisodeEthics,
  selectActiveChapterId,
  selectEpisode,
  selectIsFreeTrialUser,
  selectUserRegion,
  useAppSelector,
  userEpisodesSelectors,
} from '@/store';
import { Referrer } from '@/types';
import { createRouteWithParams } from '@/utils';

import { EpisodeToolbar } from './components';

interface EpisodeCardProps {
  episodeId: string;
  adjustWidth?: boolean;
  onLinkClick?: () => void;
  referrer?: Referrer;
}

export const EpisodeCard = ({
  episodeId,
  adjustWidth = true,
  onLinkClick,
  referrer,
}: EpisodeCardProps) => {
  const userRegion = useSelector(selectUserRegion);
  const episode = useAppSelector(state => selectEpisode(state, episodeId));
  const isFreeTrialUser = useSelector(selectIsFreeTrialUser);
  const userEpisode = useAppSelector(state =>
    userEpisodesSelectors.selectById(state, episode?.episodeId),
  );
  const activeChapterId = useSelector(selectActiveChapterId);

  const [coverLoaded, setCoverLoaded] = useState(false);

  const location = {
    ...referrer,
    component: EVENT_CONSTANTS.COMPONENT.EPISODE_CARD,
  };

  const { ref: impressionRef } = useInView({
    threshold: 0,
    triggerOnce: true,
    onChange: inView => {
      if (inView) {
        AnalyticsService.episodeImpression({
          episodeId: episode.episodeId,
          location,
          referrer,
        });
      }
    },
  });

  const { toggleBookmark: onToggleBookmark, isBookmarked } =
    useBookmarkManagement({
      episodeId,
      location,
      referrer,
    });

  const isEpisodeEthics = getIsEpisodeEthics({ episode, userRegion });
  const isEpisodeNotStarted =
    !userEpisode?.status || userEpisode?.status === EpisodeStatus.NONE;
  const isTrailerActive = activeChapterId === episode?.trailerId;
  const showPremium = episode?.isPremium && isFreeTrialUser;
  const hideToolbar = isEpisodeNotStarted && !isTrailerActive && !showPremium;

  return (
    <>
      {!episode ? (
        <EpisodeCardSkeleton includeText={true} />
      ) : (
        <Box
          ref={impressionRef}
          sx={[styles.root, adjustWidth && styles.cardWidthAdjust]}>
          <Link
            to={{
              pathname: createRouteWithParams(Routes.EPISODE, {
                episodeId: episode.episodeId,
              }),
            }}
            onClick={onLinkClick}
            state={{ referrer: location }}>
            <InView
              rootMargin="1000px 0px 1000px 0px"
              threshold={0}
              triggerOnce>
              {({ inView, ref: coverRef }) => (
                <Box sx={styles.cover} ref={coverRef} id="episode-card-cover">
                  {inView && (
                    <>
                      <Box
                        component="img"
                        id="episode-card-img"
                        sx={[
                          styles.coverImg,
                          { display: coverLoaded ? 'block' : 'none' },
                        ]}
                        src={episode.cover}
                        alt="cover"
                        onLoad={() => setCoverLoaded(true)}
                      />
                      {episode.episodeAgeTag === 'new' && (
                        <CardFlair label="NEW!" />
                      )}
                      <Box
                        component="img"
                        sx={[
                          styles.companyLogoImg,
                          { display: coverLoaded ? 'block' : 'none' },
                        ]}
                        src={episode.companyLogo}
                        alt="company logo"
                      />
                    </>
                  )}
                  {!coverLoaded && <EpisodeCardSkeleton />}
                  <Box
                    id="episode-card-toolbar"
                    sx={[
                      styles.toolbarContainer,
                      hideToolbar && styles.hideToolbar,
                    ]}>
                    <EpisodeToolbar
                      episodeId={episode.episodeId}
                      referrer={location}
                    />
                  </Box>
                </Box>
              )}
            </InView>
          </Link>
          <Box>
            <Box sx={styles.tagContainer}>
              <DurationPill duration={episode.duration} />
              {isEpisodeEthics && <EthicsPill />}
              {isBookmarked ? (
                <Bookmark
                  onClick={onToggleBookmark}
                  sx={[styles.bookmarkIcon, styles.bookmarkIconActive]}
                />
              ) : (
                <BookmarkBorder
                  onClick={onToggleBookmark}
                  sx={styles.bookmarkIcon}
                />
              )}
            </Box>
            <Link
              variant={LinkVariants.PLAIN}
              to={{
                pathname: createRouteWithParams(Routes.EPISODE, {
                  episodeId: episode.episodeId,
                }),
              }}
              onClick={onLinkClick}
              state={{ referrer: location }}>
              <Box id="episode-card-text" sx={styles.titleContainer}>
                <Typography variant="subtitle2">{episode.name}</Typography>
              </Box>
            </Link>
          </Box>
        </Box>
      )}
    </>
  );
};

const styles = {
  root: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    width: theme.dimensions.episodeCardCover.width,
    height: '100%',
    minHeight:
      theme.dimensions.episodeCardCover.height +
      theme.dimensions.episodeCardText.height,
    '&:has(#episode-card-text:hover) #episode-card-img': {
      transform: 'scale(1.1)',
    },
    '&:has(#episode-card-text:hover) #episode-card-toolbar': {
      transform: 'translateY(0)',
      opacity: 1,
    },
    '&:has(#episode-card-cover:hover) #episode-card-toolbar': {
      transform: 'translateY(0)',
      opacity: 1,
    },
  }),
  cover: {
    position: 'relative',
    overflow: 'hidden',
    height: ({ dimensions }: Theme) => dimensions.episodeCardCover.height,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover #episode-card-img': {
      transform: 'scale(1.1)',
    },
  },
  coverImg: {
    zIndex: 0,
    transition: 'transform .25s ease-in-out, opacity 500ms',
    cursor: 'pointer',
    height: '100%',
    // Apply a slight scale by default to prevent jittering on hover
    transform: 'scale(1.005)',
  },
  companyLogoImg: {
    position: 'absolute',
    top: 8,
    left: 8,
    borderRadius: 2,
    height: 38,
    width: 38,
    pointerEvents: 'none',
  },
  toolbarContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: 46,
    width: '100%',
    background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 100%)`,
    px: 1,
    pb: 1,
  },
  hideToolbar: {
    opacity: 0,
    transform: 'translateY(30%)',
    transition: 'opacity 0.3s, transform 0.3s',
  },
  titleContainer: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    mb: 0.5,
    WebkitLineClamp: 3,
    cursor: 'pointer',
  },
  tagContainer: {
    display: 'flex',
    gap: 1,
    flexDirection: 'row',
    alignItems: 'center',
    my: 0.5,
  },
  cardWidthAdjust: (theme: Theme) => ({
    [theme.breakpoints.down('sm')]: {
      width: `min(${theme.dimensions.episodeCardCover.width}px, 50vw - 50px)`,
    },
  }),
  bookmarkIcon: {
    ml: 'auto',
    cursor: 'pointer',
    color: 'neutral30',
    width: 22,
    height: 22,
    '&:hover': {
      opacity: 0.8,
    },
  },
  bookmarkIconActive: {
    color: 'blue30',
  },
};
