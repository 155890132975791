import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { EpisodeStatus, Modals, Routes } from '@/constants';
import { EVENT_CONSTANTS } from '@/services';
import {
  playerThunks,
  selectActiveChapterId,
  selectActiveEpisodeId,
  selectEpisode,
  selectIsFreeTrialUser,
  selectUserRegion,
  uiActions,
  useAppDispatch,
  useAppSelector,
  useFetchChapterQuery,
  userEpisodesSelectors,
} from '@/store';
import { Referrer } from '@/types';
import { createRouteWithParams } from '@/utils';

import {
  selectEpisodePlayerPosition,
  selectIsEpisodePlaying,
} from '../../selectors';
import { EpisodeToolbar as EpisodeToolbarComponent } from './EpisodeToolbar.component';

export const EpisodeToolbar = ({
  episodeId,
  referrer,
}: {
  episodeId: string;
  referrer: Referrer;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const playerPosition =
    useAppSelector(state => selectEpisodePlayerPosition(state, episodeId)) ?? 0;
  const activeEpisodeId = useAppSelector(selectActiveEpisodeId);
  const activeChapterId = useAppSelector(selectActiveChapterId);
  const episode = useAppSelector(state => selectEpisode(state, episodeId));
  const userEpisode = useAppSelector(state =>
    userEpisodesSelectors.selectById(state, episode?.episodeId),
  );
  const region = useAppSelector(selectUserRegion);
  const isFreeTrialUser = useAppSelector(selectIsFreeTrialUser);

  const userEpisodeStatus = userEpisode?.status;
  const episodeDuration = episode?.duration;
  const playedDuration = userEpisode?.playedDuration || 0;

  const isEpisodePlaying = useAppSelector(state =>
    selectIsEpisodePlaying(state, episodeId),
  );
  const isTrailerActive =
    activeEpisodeId === episodeId && activeChapterId === episode.trailerId;

  const { trailerDuration = 0 } = useFetchChapterQuery(
    {
      chapterId: episode.trailerId,
      region,
    },
    {
      skip: !isTrailerActive,
      selectFromResult: result => ({
        trailerDuration: result.data?.duration,
      }),
    },
  );

  const navigateToEpisode = useCallback(() => {
    navigate(
      {
        pathname: createRouteWithParams(Routes.EPISODE, {
          episodeId,
        }),
      },
      {
        state: { referrer },
      },
    );
  }, [navigate, episodeId, referrer]);

  const onClickHandler = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      if (!userEpisodeStatus || userEpisodeStatus === EpisodeStatus.NONE) {
        dispatch(
          playerThunks.chapterPlayPressed({
            activeChapter: { episodeId, chapterId: episode.trailerId },
            location: { ...referrer, button: EVENT_CONSTANTS.BUTTON.PREVIEW },
            referrer,
          }),
        );
      }
      if (userEpisodeStatus === EpisodeStatus.ON_GOING) {
        dispatch(
          playerThunks.episodePlayPressed({
            episodeId,
            location: { ...referrer, button: EVENT_CONSTANTS.BUTTON.PLAY },
            referrer,
          }),
        );
      }
      if (userEpisodeStatus === EpisodeStatus.QUIZ_READY) {
        navigateToEpisode();
        dispatch(
          uiActions.setActiveModal({
            name: Modals.CHAPTER_QUIZ,
            params: {
              episodeId,
              showFirstIncompleteQuiz: true,
            },
          }),
        );
      }
      if (userEpisodeStatus === EpisodeStatus.ASSESSMENT_READY) {
        navigateToEpisode();
        dispatch(
          uiActions.setActiveModal({
            name: Modals.ASSESSMENT,
            params: { episodeId },
          }),
        );
      }
      if (userEpisodeStatus === EpisodeStatus.VERIFIED) {
        navigateToEpisode();
      }
    },
    [
      navigateToEpisode,
      dispatch,
      episode.trailerId,
      episodeId,
      referrer,
      userEpisodeStatus,
    ],
  );

  return (
    <EpisodeToolbarComponent
      episodeStatus={userEpisodeStatus}
      isTrailerActive={isTrailerActive}
      isEpisodePlaying={isEpisodePlaying}
      onClick={onClickHandler}
      playerPosition={playerPosition}
      episodePlayedDuration={playedDuration}
      trailerDuration={trailerDuration}
      episodeDuration={episodeDuration}
      showPremium={!!(episode?.isPremium && isFreeTrialUser)}
    />
  );
};
