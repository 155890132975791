import { Chip, ChipProps } from '@mui/material';

export const Pill = (props: ChipProps) => {
  return (
    <Chip
      sx={theme => ({
        ...theme.typography.caption,
        width: 'max-content',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        px: 0.5,
        py: 0.25,
        '& .MuiChip-deleteIcon': {
          color: 'white',
          '&:hover': {
            color: 'white',
            opacity: '0.8',
          },
        },
      })}
      {...props}
    />
  );
};
