import { Box, useMediaQuery } from '@mui/material';
import { useState } from 'react';

import { PageTabs } from '@/components';
import { AnalyticsService, EVENT_CONSTANTS } from '@/services';

import {
  AdditionalResources,
  EpisodeChapterList,
  EpisodeControlButtons,
  EpisodeCoverImage,
  EpisodeDescription,
  EpisodeHeader,
  RelatedEpisodes,
  Speakers,
} from './components';

export const MOBILE_TABS = [
  { value: 'overview', label: 'Overview' },
  { value: 'chapters', label: 'Chapters & Quizzes' },
];

export const EpisodeDetails = ({ episode, customControls }) => {
  const [selectedTab, setSelectedTab] = useState('overview');

  const referrer = { page: EVENT_CONSTANTS.PAGE.EPISODE_DETAILS };

  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));

  if (isSmDown) {
    return (
      <Box sx={styles.rootMobile}>
        <EpisodeCoverImage episode={episode} />
        <EpisodeHeader episode={episode} />
        {customControls || (
          <EpisodeControlButtons episode={episode} referrer={referrer} />
        )}
        <PageTabs
          tabs={MOBILE_TABS}
          selectedTab={selectedTab}
          onChange={(e, newVal) => {
            AnalyticsService.episodeDetailSwitchTab({
              episodeId: episode.id,
              tabName: newVal,
            });
            setSelectedTab(newVal);
          }}
        />
        {selectedTab === MOBILE_TABS[0].value && (
          <EpisodeDescription episode={episode} />
        )}
        {selectedTab === MOBILE_TABS[1].value && (
          <>
            <EpisodeChapterList episode={episode} referrer={referrer} />
            <AdditionalResources episode={episode} />
            <Speakers episode={episode} />
          </>
        )}
        <RelatedEpisodes
          episode={episode}
          allowSingleColumn={false}
          referrer={referrer}
        />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={styles.root}>
        <Box sx={styles.leftColumn}>
          <Box sx={styles.topLeftCell}>
            <EpisodeHeader episode={episode} />
            {customControls || (
              <EpisodeControlButtons episode={episode} referrer={referrer} />
            )}
          </Box>
          <EpisodeDescription episode={episode} />
          <Speakers episode={episode} />
          <RelatedEpisodes
            episode={episode}
            allowSingleColumn={true}
            referrer={referrer}
          />
        </Box>
        <Box sx={styles.rightColumn}>
          <EpisodeCoverImage episode={episode} />
          <EpisodeChapterList episode={episode} referrer={referrer} />
          <AdditionalResources episode={episode} />
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  rootMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  leftColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    ml: 3,
    minWidth: {
      xs: 272,
      lg: 342,
    },
    width: { xs: 272, lg: 342 },
    gap: 4,
  },
  topLeftCell: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 212,
  },
};
