import { Alert, Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Skeleton } from '@/components';
import { EpisodeDetails } from '@/containers';
import { AnalyticsService, useGrowthbookReady } from '@/services';
import { selectUserRegion, useFetchEpisodeQuery } from '@/store';

import { WarningBanners } from './components';

export const Episode = () => {
  const { episodeId } = useParams();
  const region = useSelector(selectUserRegion);

  // TODO useFetchEpisodequery causes unecessary
  // fetching waterfalls in EpisodeDetails
  const {
    data: episode,
    isLoading,
    isUninitialized,
  } = useFetchEpisodeQuery({ episodeId, region, includeArchived: true });

  // Try to fetch non-region episode if not found
  const { data: fallbackEpisode, isLoading: isFallbackLoading } =
    useFetchEpisodeQuery(
      { episodeId, includeArchived: true },
      { skip: isUninitialized || isLoading || episode },
    );

  useEffect(() => {
    const targetEpisode = episode || fallbackEpisode;
    if (targetEpisode) {
      AnalyticsService.pageViewEpisode(targetEpisode);
    }
  }, [episode, fallbackEpisode]);

  const gbReady = useGrowthbookReady();

  if (isLoading || isUninitialized || isFallbackLoading || !gbReady) {
    return (
      <Box>
        <Skeleton variant="rounded" width="100%" height={260} />
        <Box mt={2}>
          {Array.from({ length: 8 }).map((_, i) => (
            <Skeleton key={i} width="80%" />
          ))}
        </Box>
      </Box>
    );
  }

  if (episode) {
    return (
      <Box>
        <WarningBanners episode={episode} />
        <EpisodeDetails episode={episode} />
      </Box>
    );
  }

  if (fallbackEpisode) {
    return (
      <Box>
        <WarningBanners episode={fallbackEpisode} isEpisodeNotNasba={true} />
        <EpisodeDetails episode={fallbackEpisode} />
      </Box>
    );
  }

  return (
    <Alert severity="error">
      <Typography variant="select">
        Sorry, the episode you were looking for was not found.
      </Typography>
    </Alert>
  );
};
