import * as R from 'ramda';

export const FONT_SIZE = 14;
export const HTML_FONT_SIZE = 16;
// const coef = FONT_SIZE / 14;

// const pxToRem = (px: number) => `${(px / HTML_FONT_SIZE) * coef}rem`;

const Weight = {
  thin: 100,
  extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};

// const Scale = [12, 13.5, 16, 19, 23, 27, 33, 40, 48];
// const LineHeight = [16, 18, 21, 24, 30, 36, 43, 52, 62];

const Scale = {
  1: 12,
  2: 13.5,
  3: 16,
  4: 19,
  5: 23,
  6: 27,
  7: 33,
  8: 40,
  9: 48,
  10: 57,
  11: 68,
};

const LineHeight = R.map(val => val + 'px', {
  1: 16,
  2: 18,
  3: 21,
  4: 24,
  5: 30,
  6: 36,
  7: 43,
  8: 52,
  9: 62,
  10: 68,
  11: 81,
});

const LetterSpacing = {
  tightest: -1.5,
  tighter: -1,
  tight: -0.5,
  nil: 0,
  loose: 0.5,
  looser: 1,
  loosest: 1.5,
};

const hero1 = {
  fontSize: Scale[11],
  lineHeight: LineHeight[11],
  fontWeight: Weight.bold,
  letterSpacing: LetterSpacing.tight,
};

const hero2 = {
  fontSize: Scale[10],
  lineHeight: LineHeight[10],
  fontWeight: Weight.bold,
  letterSpacing: LetterSpacing.tight,
};

const hero3 = {
  fontSize: Scale[9],
  lineHeight: LineHeight[9],
  fontWeight: Weight.bold,
  letterSpacing: LetterSpacing.tight,
};

const h1 = {
  fontSize: Scale[7],
  lineHeight: LineHeight[7],
  fontWeight: Weight.bold,
  letterSpacing: LetterSpacing.tight,
};

const h2 = {
  fontSize: Scale[6],
  lineHeight: LineHeight[6],
  fontWeight: Weight.bold,
  letterSpacing: LetterSpacing.tight,
};

const h3 = {
  fontSize: Scale[5],
  lineHeight: LineHeight[5],
  fontWeight: Weight.bold,
  letterSpacing: LetterSpacing.nil,
};

const h4 = {
  fontSize: Scale[4],
  lineHeight: LineHeight[4],
  fontWeight: Weight.bold,
  letterSpacing: LetterSpacing.nil,
};

const h5 = {
  fontSize: Scale[3],
  lineHeight: LineHeight[3],
  fontWeight: Weight.bold,
  letterSpacing: LetterSpacing.nil,
};

const h6 = {
  fontSize: Scale[2],
  lineHeight: LineHeight[3],
  fontWeight: Weight.semiBold,
  letterSpacing: LetterSpacing.nil,
};

const subtitle1 = {
  fontSize: Scale[3],
  lineHeight: LineHeight[3],
  fontWeight: Weight.semiBold,
  letterSpacing: LetterSpacing.nil,
};

const subtitle2 = {
  fontSize: Scale[2],
  lineHeight: LineHeight[2],
  fontWeight: Weight.semiBold,
  letterSpacing: LetterSpacing.nil,
};

const subtitle3 = {
  fontSize: Scale[1],
  lineHeight: LineHeight[1],
  fontWeight: Weight.semiBold,
  letterSpacing: LetterSpacing.nil,
};

const body1 = {
  fontSize: Scale[3],
  lineHeight: LineHeight[4],
  fontWeight: Weight.regular,
  letterSpacing: LetterSpacing.tight,
};

const body2 = {
  fontSize: Scale[3],
  lineHeight: LineHeight[3],
  fontWeight: Weight.regular,
  letterSpacing: LetterSpacing.tight,
};

const body3 = {
  fontSize: Scale[2],
  lineHeight: LineHeight[2],
  fontWeight: Weight.regular,
  letterSpacing: LetterSpacing.nil,
};

const button1 = {
  fontSize: Scale[4],
  lineHeight: LineHeight[4],
  fontWeight: Weight.semiBold,
  letterSpacing: LetterSpacing.nil,
};

const button2 = {
  fontSize: Scale[3],
  lineHeight: LineHeight[3],
  fontWeight: Weight.semiBold,
  letterSpacing: LetterSpacing.nil,
};

const button3 = {
  fontSize: Scale[2],
  lineHeight: LineHeight[4],
  fontWeight: Weight.semiBold,
  letterSpacing: LetterSpacing.nil,
};

const caption = {
  fontSize: Scale[1],
  lineHeight: LineHeight[1],
  fontWeight: Weight.medium,
  letterSpacing: LetterSpacing.nil,
};

const select = {
  fontSize: Scale[2],
  lineHeight: LineHeight[2],
  fontWeight: Weight.regular,
  letterSpacing: LetterSpacing.nil,
};

export const Typography = {
  hero1,
  hero2,
  hero3,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  subtitle1,
  subtitle2,
  subtitle3,
  body1,
  body2,
  body3,
  button1,
  button2,
  button3,
  caption,
  select,
};

export const MobileTypography: Record<string, TypographyVariant> = {
  hero1: hero2,
  hero2: hero3,
  hero3: h1,
  h1: h2,
  h2: h3,
  h3: h4,
  h4: h5,
  h5: h6,
  h6,
  subtitle1,
  subtitle2,
  subtitle3,
  body1,
  body2,
  body3,
  button1,
  button2,
  caption,
  select,
};

export type TypographyVariant = {
  fontFamily?: string;
  fontSize: number;
  lineHeight: string;
  fontWeight: number;
  letterSpacing: number;
};
