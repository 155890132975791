import { CheckRounded } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

export const EthicsPill = ({ color }: { color?: string }) => (
  <Box
    sx={{
      display: 'flex',
      gap: 0.25,
      flexDirection: 'row',
      alignItems: 'center',
    }}>
    <CheckRounded sx={{ color: color ?? 'neutral30', width: 13, height: 13 }} />
    <Typography variant="subtitle3" color={color ?? 'neutral30'}>
      Ethics
    </Typography>
  </Box>
);
