import { Box, TextField, Typography } from '@mui/material';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, FeedbackRatings } from '@/components';
import { Modals } from '@/constants';
import { useFocusListener } from '@/hooks';
import { productFeedback } from '@/images';
import { AnalyticsService, EVENT_CONSTANTS } from '@/services';
import {
  selectActiveModal,
  selectSessionUserId,
  uiActions,
  useShowProductFeedbackIfReadyMutation,
  useSubmitProductFeedbackMutation,
} from '@/store';

import { AppModal } from '../AppModal';

const TIME_SINCE_APP_LOAD = 1000 * 60 * 5; // 5 minutes

export const ProductFeedbackModal = () => {
  const dispatch = useDispatch();
  const hasFocus = useFocusListener();

  const userId = useSelector(selectSessionUserId);
  const activeModal = useSelector(selectActiveModal);

  const [isTimeElapsed, setIsTimeElapsed] = useState(false);

  const [showProductFeedbackIfReady] = useShowProductFeedbackIfReadyMutation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsTimeElapsed(true);
    }, TIME_SINCE_APP_LOAD);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (isTimeElapsed && hasFocus && !activeModal && userId) {
      showProductFeedbackIfReady();
      setIsTimeElapsed(false);
    }
  }, [
    showProductFeedbackIfReady,
    isTimeElapsed,
    hasFocus,
    activeModal,
    userId,
  ]);

  const [submitFeedbackRequest, { isLoading: isSubmitting }] =
    useSubmitProductFeedbackMutation();

  const [rating, setRating] = useState(null);
  const [textFeedback, setTextFeedback] = useState('');

  const submitFeedback = async () => {
    await submitFeedbackRequest({
      feedback: {
        productRating: !R.isNil(rating) ? rating + 1 : null,
        textFeedback,
        isDismissed: R.isNil(rating),
      },
    });

    AnalyticsService.productFeedback({
      productRating: rating,
      textFeedback,
      isDismissed: R.isNil(rating),
      referrer: {
        component: EVENT_CONSTANTS.COMPONENT.MODAL,
        name: Modals.PRODUCT_FEEDBACK,
      },
    });
    dispatch(uiActions.closeActiveModal());
  };

  return (
    <AppModal modalName={Modals.PRODUCT_FEEDBACK} onClose={submitFeedback}>
      <Box sx={styles.container}>
        <Typography variant="h1" sx={{ fontWeight: 600, mb: 3 }}>
          How are you liking the LumiQ Web Player so far?
        </Typography>
        <Box>
          <img
            src={productFeedback}
            alt="Product Feedback"
            style={{
              height: 272,
            }}
          />
        </Box>
        <Typography variant="subtitle3" sx={{ mt: 2 }}>
          (1 = not at all, 5 = shout it from the mountain top!)
        </Typography>
        <FeedbackRatings
          rating={rating}
          onRatingChange={index => setRating(index)}
          ratingScale={5}
        />
        {!R.isNil(rating) && (
          <>
            <Typography variant="subtitle2" sx={{ fontWeight: '400', mt: 4 }}>
              Do you have any additional feedback? (Optional)
            </Typography>
            <TextField
              variant="standard"
              multiline
              maxRows={4}
              placeholder="Click here and start typing"
              value={textFeedback}
              onChange={e => setTextFeedback(e.target.value)}
              InputProps={{
                style: {
                  fontSize: '14px',
                  lineHeight: '14px',
                },
              }}
              sx={styles.textField}
            />
          </>
        )}
        <Button
          variant="primary"
          label="Submit"
          fullWidth={false}
          disabled={isSubmitting || R.isNil(rating)}
          onClick={submitFeedback}
        />
      </Box>
    </AppModal>
  );
};

const styles = {
  container: {
    p: 4,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  reasonsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '70%',
    m: 3,
  },
  reason: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '50%',
    cursor: 'pointer',
  },
  textField: {
    bgcolor: 'neutral10tint1',
    borderRadius: 1,
    width: '70%',
    mt: 2,
    mb: 2,
    p: 2,
  },
};
