import { createSvgIcon } from '@mui/material/utils';

export const MembersActiveIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="7" r="4" fill="currentColor" />
    <circle cx="7" cy="6" r="3" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.29532 17C8.17527 14.8121 9.81177 13.0179 11.8763 11.9784C10.6157 10.7539 8.89565 10 6.99947 10C3.6827 10 0.904703 12.3068 0.182339 15.4036C-0.0196443 16.2695 0.720268 17 1.60941 17H7.29532Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.8986 17.6938C24.3265 18.89 23.3373 20 22.1063 20H9.89372C8.66272 20 7.67348 18.89 8.10138 17.6938C9.29064 14.3693 12.3783 12 16 12C19.6217 12 22.7094 14.3693 23.8986 17.6938Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.8986 17.6938C24.3265 18.89 23.3373 20 22.1063 20H9.89372C8.66272 20 7.67348 18.89 8.10138 17.6938C9.29064 14.3693 12.3783 12 16 12C19.6217 12 22.7094 14.3693 23.8986 17.6938Z"
      fill="currentColor"
    />
  </svg>,
  'MembersActive',
);
