import { createAsyncThunk } from '@reduxjs/toolkit';

import { Modals, PlanTypes } from '@/constants';
import { selectSessionUserId } from '@/store/session';
import { uiActions } from '@/store/ui';
import { selectIsFreeTrialUser, selectPlan } from '@/store/user-api';

import { episodesApi } from '../episodes-api';
import { RootState } from '../store';
import { USER_EPISODES_SLICE_KEY } from './user-settings.constants';

const initiateRedemption = createAsyncThunk<
  void,
  { episodeId: string },
  {
    rejectValue: string;
    state: RootState;
  }
>(
  `${USER_EPISODES_SLICE_KEY}/initiateRedemption`,
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const { episodeId } = payload;

    const userId = selectSessionUserId(getState());
    if (!userId) {
      dispatch(uiActions.setActiveModal({ name: Modals.SIGN_UP }));
      return;
    }

    const episode = await dispatch(
      episodesApi.endpoints.fetchEpisode.initiate(
        { episodeId, region: 'USA' },
        { subscribe: false },
      ),
    ).unwrap();

    if (!episode) {
      return rejectWithValue('Episode not found');
    }

    const isFreeTrialUser = selectIsFreeTrialUser(getState());
    if (episode.isPremium && isFreeTrialUser) {
      dispatch(uiActions.setActiveModal({ name: Modals.PREMIUM_LOCKED }));
      return;
    }

    const { data: plan } = selectPlan(getState());
    if (!plan || plan.planType === PlanTypes.NONE) {
      dispatch(uiActions.setActiveModal({ name: Modals.INVALID_PLAN }));
      return;
    }

    dispatch(
      uiActions.setActiveModal({ name: Modals.REDEEM, params: payload }),
    );
  },
);

export const userEpisodesThunks = {
  initiateRedemption,
};
