import { Box, Typography } from '@mui/material';

import { AssessmentAnswer, QuizAnswer } from '@/types';

import { CheckmarkAnimation } from '../CheckmarkAnimation';
import { Skeleton } from '../Skeleton';

interface AnswerBoxProps {
  answer: QuizAnswer | AssessmentAnswer;
  onSelect: (value: string | number) => void;
  isSelected: boolean;
  isSubmitted?: boolean;
  index: number;
  isPending?: boolean;
  isDisabled?: boolean;
  questionId: string;
  showCaption?: boolean;
}

export const AnswerBox = ({
  onSelect,
  answer,
  isSelected,
  index,
  isPending,
  isDisabled,
  questionId,
  isSubmitted,
  showCaption,
}: AnswerBoxProps) => {
  const isTestMode = localStorage.getItem('testMode') === 'true';
  const value =
    (answer as QuizAnswer).id ||
    (answer as AssessmentAnswer).assessmentAnswerId;
  const answerLetter = String.fromCharCode(65 + index);

  return (
    <>
      <Box
        component="label"
        sx={[
          styles.answerContainer,
          isSelected && styles.answerSelected,
          !isSelected && !isDisabled && styles.answerClickable,
          !!isSubmitted &&
            isSelected &&
            !answer.isCorrect &&
            styles.answerIncorrect,
          (isTestMode || (!!isSubmitted && isSelected)) &&
            answer.isCorrect &&
            styles.answerCorrect,
        ]}>
        {isPending ? (
          <Skeleton animation={false} />
        ) : (
          <>
            {isSubmitted && isSelected && answer.isCorrect && (
              <CheckmarkAnimation
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  transform: 'translateY(-50%)',
                }}
              />
            )}
            <Typography
              sx={[
                styles.answerLetter,
                isSelected && styles.letterSelected,
                (isTestMode || (!!isSubmitted && isSelected)) &&
                  answer.isCorrect &&
                  styles.letterCorrect,
                !!isSubmitted &&
                  isSelected &&
                  !answer.isCorrect &&
                  styles.letterIncorrect,
              ]}
              variant="body3">
              {answerLetter}
            </Typography>
            <Typography variant="body2">
              {'answerLabel' in answer ? answer.answerLabel : answer.answer}
              <input
                style={{ opacity: 0, pointerEvents: 'none', position: 'fixed' }}
                type="radio"
                name={questionId}
                value={value}
                disabled={isDisabled}
                checked={isSelected}
                onChange={() => {
                  onSelect(value);
                }}
              />
            </Typography>
          </>
        )}
      </Box>
      {showCaption && (
        <>
          <Typography
            variant="h5"
            sx={{ mt: 1 }}
            color={answer.isCorrect ? 'green' : 'red'}>
            {answerLetter}) {answer.isCorrect ? 'Correct' : 'Incorrect'}:&nbsp;
          </Typography>
          <Typography variant="body3" color="neutral35">
            {(answer as QuizAnswer).caption}
          </Typography>
        </>
      )}
    </>
  );
};

const styles = {
  answerContainer: {
    display: 'block',
    position: 'relative',
    border: '3px solid',
    borderColor: 'neutral20',
    textAlign: 'center',
    borderRadius: 4,
    px: 2,
    py: 4,
    minHeight: 70,
    height: '100%',
    backgroundColor: 'white',
    color: 'neutral35',
    alignContent: 'center',
    '&:focus-within': {
      outline: 'blue30 solid 2px',
    },
  },
  answerLetter: {
    position: 'absolute',
    fontWeight: 'bold',
    top: 0,
    left: 0,
    p: 1,
  },
  answerClickable: {
    cursor: 'pointer',
  },
  answerSelected: {
    borderColor: 'blue30',
  },
  answerCorrect: {
    borderColor: 'green',
  },
  answerIncorrect: {
    borderColor: 'red',
  },
  letterSelected: {
    color: 'blue30',
  },
  letterCorrect: {
    color: 'green',
  },
  letterIncorrect: {
    color: 'red',
  },
};
