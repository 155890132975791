import { AccessTimeFilledRounded } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import * as R from 'ramda';

import { convertSecondsToHoursMinutes } from '@/utils';

export const DurationPill = ({
  duration,
  color,
  longForm = true,
}: {
  duration: number;
  color?: string;
  longForm?: boolean;
}) => {
  if (R.isNil(duration)) return null;
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 0.25,
        flexDirection: 'row',
        alignItems: 'center',
      }}>
      <AccessTimeFilledRounded
        sx={{ color: color ?? 'neutral30', width: 12, height: 12 }}
      />
      <Typography variant="subtitle3" color={color ?? 'neutral30'}>
        {convertSecondsToHoursMinutes(duration, longForm)}
      </Typography>
    </Box>
  );
};
