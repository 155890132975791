import { Box, Typography } from '@mui/material';
import { useState } from 'react';

import { useFetchPDHoursQuery } from '@/store';

import { CPESummary, CreditsTable, DateRange } from './components';

export const CPETracker = () => {
  const [dateRange, setDateRange] = useState({});

  const { data: credits } = useFetchPDHoursQuery({
    dateRange: {
      fromDate: isNaN(new Date(dateRange?.fromDate))
        ? undefined
        : dateRange?.fromDate?.toISOString(),
      toDate: isNaN(new Date(dateRange?.toDate))
        ? undefined
        : dateRange?.toDate?.toISOString(),
    },
  });

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: { xs: 'column', sm: 'row' },
        }}>
        <Box mb={{ xs: 2, sm: 0 }}>
          <Typography variant="h1">CPE Tracker</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', sm: 'flex-end' },
            ml: { xs: 0, sm: 2 },
            whiteSpace: 'nowrap',
            width: { xs: '100%', sm: 'fit-content' },
          }}>
          <DateRange
            dateRange={dateRange}
            onDateChange={values => {
              setDateRange(values);
            }}
          />
        </Box>
      </Box>
      <Box mt={4}>
        <CPESummary credits={credits} />
      </Box>
      <Box mt={4}>
        <CreditsTable credits={credits} dateRange={dateRange} />
      </Box>
    </Box>
  );
};
