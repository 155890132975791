import * as R from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modals } from '@/constants';
import { useFetchEpisodeChapterProgress } from '@/hooks';
import { AnalyticsService, EVENT_CONSTANTS } from '@/services';
import {
  playerThunks,
  selectActiveChapterId,
  selectActiveModalParams,
  selectChapterProgress,
  selectIsEpisodeRedeemRequired,
  selectIsFsmPlayer,
  selectIsPlayerPlaying,
  selectUserRegion,
  uiActions,
  useAnswerQuizMutation,
  useFetchEpisodeChaptersQuery,
  useFetchEpisodePendingQuizzesQuery,
  userEpisodesSelectors,
  userEpisodesThunks,
  useSubmitQuizLegacyMutation,
  useValidateQuizMutation,
} from '@/store';
import { SnackbarUtils } from '@/utils';

import { ChapterQuizModalContentComponent } from './ChapterQuizModalContent.component';

export const ChapterQuizModalContent = () => {
  const { episodeId, chapterId, showFirstIncompleteQuiz } =
    useSelector(selectActiveModalParams) || {};
  const region = useSelector(selectUserRegion);
  const isFsmPlayerUser = useSelector(selectIsFsmPlayer);
  const isPlayerPlaying = useSelector(selectIsPlayerPlaying);
  const activeChapterId = useSelector(selectActiveChapterId);
  const userEpisode = useSelector(state =>
    userEpisodesSelectors.selectById(state, episodeId),
  );
  const isEpisodeRedeemRequired = useSelector(state =>
    selectIsEpisodeRedeemRequired(state, episodeId),
  );

  const [selectedPageIndex, setSelectedPageIndex] = useState(null);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);

  const dispatch = useDispatch();

  const { data: chapters, isLoading: isChaptersLoading } =
    useFetchEpisodeChaptersQuery(
      { episodeId, region },
      {
        skip: !episodeId,
      },
    );

  const {
    data: episodeQuizzes,
    isLoading: isQuizzesLoading,
    refetch: refetchQuizzes,
  } = useFetchEpisodePendingQuizzesQuery(
    {
      episodeId,
      region,
    },
    {
      skip: !episodeId,
    },
  );

  useFetchEpisodeChapterProgress(episodeId);

  const [validateQuiz, { isLoading: isValidateLoading }] =
    useValidateQuizMutation();
  const [submitQuizLegacy, { isLoading: isSubmitLoadingLegacy }] =
    useSubmitQuizLegacyMutation();
  const [submitQuizFsm, { isLoading: isSubmitLoadingFsm }] =
    useAnswerQuizMutation();

  const isSubmitLoading =
    isSubmitLoadingFsm || isSubmitLoadingLegacy || isValidateLoading;

  const modalPages = useMemo(() => {
    const formattedArray = [];
    if (!chapters || !episodeQuizzes) return formattedArray;
    chapters
      ?.filter(chapter => !chapter.isTrailer)
      .forEach((chapter, chapterIndex) => {
        const chapterQuizzes =
          episodeQuizzes?.filter(q => q.chapterId === chapter.chapterId) || [];
        if (!chapterQuizzes.length) {
          formattedArray.push({
            chapter,
            chapterIndex,
            quiz: null,
            quizCountLabel: null,
          });
          return;
        }
        chapterQuizzes.forEach((quiz, index) => {
          formattedArray.push({
            chapter,
            chapterIndex,
            quiz,
            quizCountLabel:
              chapterQuizzes.length > 1
                ? `${index + 1}/${chapterQuizzes.length}`
                : null,
          });
        });
      });
    return formattedArray;
  }, [chapters, episodeQuizzes]);

  const selectedPage = modalPages[selectedPageIndex];
  const { chapter, quiz } = selectedPage || {};

  const userChapter = useSelector(state =>
    selectChapterProgress(state, {
      episodeId: chapter?.episodeId,
      chapterId: chapter?.chapterId,
    }),
  );
  const isChapterCompleted = !!userChapter?.completed;

  useEffect(() => {
    // Initialize starting page
    if (!R.isNil(selectedPageIndex) || !modalPages.length) return;

    let index = 0;
    if (showFirstIncompleteQuiz) {
      const firstIncompleteQuizIndex = modalPages.findIndex(
        page => page.quiz && !page.quiz.completed,
      );
      if (firstIncompleteQuizIndex > -1) index = firstIncompleteQuizIndex;
    } else if (chapterId) {
      const chapterIndices = modalPages.reduce((acc, page, i) => {
        if (page.chapter.chapterId === chapterId) acc.push(i);
        return acc;
      }, []);
      const firstIncompleteQuizIndex = chapterIndices.find(
        i => !modalPages[i].quiz?.completed,
      );
      if (firstIncompleteQuizIndex > -1) {
        index = firstIncompleteQuizIndex;
      } else if (chapterIndices[0] > -1) {
        index = chapterIndices[0];
      }
    }

    setSelectedPageIndex(index);
  }, [modalPages, chapterId, selectedPageIndex, showFirstIncompleteQuiz]);

  useEffect(() => {
    setSelectedAnswerIndex(null);
  }, [selectedPageIndex]);

  const isDataLoading = isChaptersLoading || isQuizzesLoading;

  const onPreviousPage = () => {
    if (selectedPageIndex === 0) return;
    setSelectedPageIndex(selectedPageIndex - 1);
    setSelectedAnswerIndex(null);
  };

  const onNextPage = () => {
    if (selectedPageIndex === modalPages.length - 1) return;
    setSelectedPageIndex(selectedPageIndex + 1);
    setSelectedAnswerIndex(null);
  };

  const onClose = () => {
    dispatch(uiActions.closeActiveModal());
  };

  const onSubmitQuizLegacy = async () => {
    const payload = {
      episodeId: chapter.episodeId,
      quizId: quiz.quizId,
      answerId: quiz.answers[selectedAnswerIndex].id,
      region,
    };
    const { data: pass } = await submitQuizLegacy(payload);

    AnalyticsService.quizAttempt({
      chapterId: chapter.chapterId,
      quizId: payload.quizId,
      answer: payload.answerId,
      pass,
    });
  };

  const onSubmitQuizFsm = async () => {
    const payloadLegacy = {
      episodeId: chapter.episodeId,
      quizId: quiz.quizId,
      answerId: quiz.answers[selectedAnswerIndex].id,
      region,
    };
    const analyticsPayload = {
      chapterId: chapter.chapterId,
      quizId: quiz.quizId,
      answer: quiz.answers[selectedAnswerIndex].id,
    };

    try {
      const { data: isAnswerCorrect, error: validateError } =
        await validateQuiz(payloadLegacy);
      if (validateError) throw new Error();

      if (!isAnswerCorrect) {
        AnalyticsService.quizAttempt({ ...analyticsPayload, pass: false });
        return;
      }

      const payload = {
        sessionId: userEpisode.sessionId,
        chapterId: chapter.chapterId,
        quizId: quiz.postgresQuizId,
      };

      const { error: submitFsmError } = await submitQuizFsm(payload);
      if (submitFsmError) throw new Error();

      const { error: submitLegacyError } =
        await submitQuizLegacy(payloadLegacy);
      if (submitLegacyError) throw new Error();
    } catch (error) {
      SnackbarUtils.warning(
        'Failed to submit quiz, please try again or contact support.',
      );
      return;
    }

    refetchQuizzes();

    AnalyticsService.quizAttempt({ ...analyticsPayload, pass: true });
  };

  const onSubmitQuiz = isFsmPlayerUser ? onSubmitQuizFsm : onSubmitQuizLegacy;

  const onPlayChapter = () => {
    dispatch(
      playerThunks.chapterPlayPressed({
        activeChapter: {
          episodeId: chapter.episodeId,
          chapterId: chapter.chapterId,
        },
        referrer: {
          page: EVENT_CONSTANTS.COMPONENT.CHAPTER_QUIZ_MODAL,
          button: EVENT_CONSTANTS.BUTTON.PLAY_CHAPTER,
        },
      }),
    );
  };

  const isQuizCompleted = !!quiz?.completed;

  const isChapterPlaying =
    isPlayerPlaying && activeChapterId === chapter?.chapterId;

  return (
    <ChapterQuizModalContentComponent
      onNextPage={onNextPage}
      onPreviousPage={onPreviousPage}
      onSelectAnswer={setSelectedAnswerIndex}
      onSubmitQuiz={onSubmitQuiz}
      onPlayChapter={onPlayChapter}
      onClose={onClose}
      showAssessment={() => {
        onClose();
        if (isEpisodeRedeemRequired) {
          dispatch(userEpisodesThunks.initiateRedemption({ episodeId }));
        } else {
          dispatch(
            uiActions.setActiveModal({
              name: Modals.ASSESSMENT,
              params: { episodeId },
            }),
          );
        }
      }}
      pages={modalPages}
      selectedPageIndex={selectedPageIndex}
      selectedAnswerIndex={selectedAnswerIndex}
      isChapterCompleted={isChapterCompleted}
      isChapterPlaying={isChapterPlaying}
      isQuizCompleted={isQuizCompleted}
      isDataLoading={isDataLoading}
      isSubmitLoading={isSubmitLoading}
      region={region}
      userEpisode={userEpisode}
      episodeId={episodeId}
    />
  );
};
