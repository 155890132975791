import { Box, Fade, Typography } from '@mui/material';
import * as R from 'ramda';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { LearningPathCard, LearningPathCardSkeleton } from '@/components';
import { APP_LAYOUT_PX } from '@/containers';
import { EVENT_CONSTANTS } from '@/services';
import {
  selectUserRegion,
  useFetchLearningPathsQuery,
  userEpisodesSelectors,
} from '@/store';
import { LearningPath } from '@/types';

import {
  calculateDurationLeft,
  getCategoryCopy,
  sortAndGroupLearningPaths,
} from './utils';

export const LearningPaths = () => {
  const userRegion = useSelector(selectUserRegion);
  const userEpisodes = useSelector(userEpisodesSelectors.selectEntities);

  const {
    data: learningPaths,
    isLoading,
    isUninitialized,
  } = useFetchLearningPathsQuery({
    region: userRegion,
  });

  const groupedLps = useMemo(() => {
    if (!learningPaths) return [];
    return R.pipe(
      (lps: LearningPath[]) => calculateDurationLeft({ lps, userEpisodes }),
      sortAndGroupLearningPaths,
    )(learningPaths);
  }, [userEpisodes, learningPaths]);

  return (
    <Box sx={styles.root}>
      <Typography variant="h1">Learning Paths</Typography>
      {isLoading || isUninitialized ? (
        <Box sx={styles.skeletonContainer}>
          {Array.from({ length: 9 }).map((_, index) => (
            <LearningPathCardSkeleton key={index} />
          ))}
        </Box>
      ) : (
        groupedLps.map(({ groupKey, isOrgLp, lps }) => (
          <Box key={groupKey}>
            <Typography variant="h3" sx={{ mb: 2 }}>
              {getCategoryCopy({ category: groupKey, userRegion })}
            </Typography>
            <Box sx={styles.listContainer}>
              <Box sx={styles.listContents(!isOrgLp)}>
                {lps.map((lp, index) => (
                  <Fade
                    key={lp.groupId}
                    in
                    timeout={Math.log10(index + 2) * 2000}>
                    <Box>
                      <LearningPathCard
                        learningPath={lp}
                        isHorizontalVariant={!isOrgLp}
                        referrer={{
                          page: EVENT_CONSTANTS.PAGE.LEARNING_PATHS,
                          component:
                            EVENT_CONSTANTS.COMPONENT.LEARNING_PATH_CARD,
                          position: index + 1,
                        }}
                      />
                    </Box>
                  </Fade>
                ))}
              </Box>
            </Box>
          </Box>
        ))
      )}
    </Box>
  );
};

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: {
      xs: 4,
      md: 6,
    },
  },
  listContainer: {
    overflowX: 'auto',
    mx: R.map(R.negate, APP_LAYOUT_PX),
    px: APP_LAYOUT_PX,
  },
  listContents: (isHorizontalVariant: boolean) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: {
      xs: isHorizontalVariant ? 2 : 4,
      md: isHorizontalVariant ? 4 : 6,
    },
    flexWrap: 'wrap',
    width: {
      xs: 'max-content',
      md: 'auto',
    },
  }),
  skeletonContainer: {
    display: 'flex',
    gap: 3,
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    flexWrap: 'wrap',
  },
};
