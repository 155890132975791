import * as R from 'ramda';

import { LUMIQ_ORGANIZATION_NAME } from '@/constants';
import {
  LearningPath,
  LearningPathWithDuration,
  Region,
  UserEpisode,
} from '@/types';

const isOrgLp = (lp: LearningPathWithDuration) =>
  lp.organizationName !== LUMIQ_ORGANIZATION_NAME;

const sortByTitle = R.sortBy(R.prop('title'));

export const sortAndGroupLearningPaths = (lps: LearningPathWithDuration[]) => {
  const groupedLps: Record<
    string,
    { groupKey: string; isOrgLp: boolean; lps: LearningPathWithDuration[] }
  > = {};

  lps.forEach(lp => {
    const key = isOrgLp(lp) ? lp.organizationName : lp.category;
    if (!groupedLps[key]) {
      groupedLps[key] = {
        groupKey: key,
        isOrgLp: isOrgLp(lp),
        lps: [],
      };
    }
    groupedLps[key].lps.push(lp);
  });

  const sortedGroupedLps = Object.values(groupedLps)
    .sort((a, b) => {
      if (a.isOrgLp && !b.isOrgLp) return -1;
      if (!a.isOrgLp && b.isOrgLp) return 1;
      return a.groupKey.localeCompare(b.groupKey);
    })
    .map(group => ({ ...group, lps: sortByTitle(group.lps) }));

  return sortedGroupedLps;
};

export const calculateDurationLeft = ({
  lps,
  userEpisodes,
}: {
  lps: LearningPath[];
  userEpisodes: Record<string, UserEpisode>;
}) => {
  return lps.map(
    lp =>
      ({
        ...lp,
        durationPlayed: lp.episodes.reduce(
          (sum, episode) =>
            sum + (userEpisodes[episode.episodeId]?.playedDuration || 0),
          0,
        ),
      }) as LearningPathWithDuration,
  );
};

export const getCategoryCopy = ({
  category,
  userRegion,
}: {
  category: string;
  userRegion: Region;
}) => {
  if (userRegion === 'USA' && category === 'Finance & Accounting') {
    return 'Finance, Accounting & Tax';
  }

  return category;
};
