import { PageContainer } from '../components';
import { RegisterForm } from './RegisterForm';

export const RegisterPage = () => {
  return (
    <PageContainer>
      <RegisterForm />
    </PageContainer>
  );
};
