import { fetchUserAssessmentRequest } from '@/requests';
import { Assessment, UserEpisodeFsm } from '@/types';
import { camelCaseKeys } from '@/utils';

import { playerFsmApi } from '../player-fsm-api';
import { postgrestApi } from '../postgrest-api';
import { PostgrestApiTags } from '../store.constants';
import { handleOnQueryStartedError } from '../store.utils';

export const assessmentsApi = postgrestApi.injectEndpoints({
  endpoints: builder => ({
    fetchEpisodeAssessment: builder.query<
      Assessment & { sessionId?: string },
      { episodeId: string }
    >({
      query: fetchUserAssessmentRequest,
      transformResponse: (response: unknown[]) => camelCaseKeys(response),
      onQueryStarted: async (_, { dispatch, queryFulfilled, getState }) => {
        try {
          const { data } = await queryFulfilled;

          const episodeId = data?.episodeId;
          if (!episodeId) return;

          const savedSession =
            playerFsmApi.endpoints.fetchPlayerSessions.select(undefined)(
              getState(),
            )?.data?.entities[episodeId] as UserEpisodeFsm;

          if (!savedSession) {
            dispatch(
              postgrestApi.util.invalidateTags([
                PostgrestApiTags.PLAYER_SESSIONS,
              ]),
            );
          }
        } catch (error) {
          handleOnQueryStartedError(error);
        }
      },
    }),
  }),
});

export const { useFetchEpisodeAssessmentQuery } = assessmentsApi;
