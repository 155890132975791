import { Typography } from '@mui/material';

import { PALETTE } from '@/theme';

export type Props = {
  state: string;
};

export const MemberState = ({ state }: Props) => {
  let label = state;
  let color: string = PALETTE.red;
  switch (state.toLowerCase()) {
    case 'unassigned':
      label = 'Unassigned';
      color = PALETTE.red;
      break;
    case 'invited':
      label = 'Invited';
      color = PALETTE.gold;
      break;
    case 'active':
      label = 'Enrolled';
      color = PALETTE.teal30;
      break;
    case 'archived':
      label = 'Removed';
      color = PALETTE.neutral20;
      break;
  }

  return (
    <Typography variant="body3">
      <Typography component="span" variant="h4" lineHeight="18px" color={color}>
        &bull;
      </Typography>
      &nbsp;
      {label}
    </Typography>
  );
};
