import { emailRegex } from '@/constants';

import { LoginFormState } from './types';

const isStringNotEmpty = (string: string) => string?.trim().length > 0;
const isEmailValid = (form: LoginFormState) =>
  isStringNotEmpty(form.email?.trim()) && emailRegex.test(form.email?.trim());
const isPasswordValid = (form: LoginFormState) =>
  isStringNotEmpty(form.password?.trim());

export const FormConfig = {
  email: {
    validator: isEmailValid,
    errorMessage: 'Please enter a valid email address.',
  },
  password: {
    validator: isPasswordValid,
    errorMessage: 'Please enter a valid password.',
  },
} as const;
