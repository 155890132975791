import { useInView } from 'react-intersection-observer';

import { useBookmarkManagement } from '@/hooks';
import { AnalyticsService, EVENT_CONSTANTS } from '@/services';
import {
  getIsEpisodeEthics,
  playerThunks,
  selectIsEpisodeRedeemRequired,
  selectIsFreeTrialUser,
  selectUserRegion,
  useAppDispatch,
  useAppSelector,
  userEpisodesThunks,
} from '@/store';
import { EpisodeCard, Referrer } from '@/types';

import { EpisodeBanner as EpisodeBannerComponent } from './EpisodeBanner.component';

export const EpisodeBanner = ({
  episode,
  referrer,
}: {
  episode: EpisodeCard;
  referrer: Referrer;
}) => {
  const dispatch = useAppDispatch();

  const userRegion = useAppSelector(selectUserRegion);
  const isFreeTrialUser = useAppSelector(selectIsFreeTrialUser);
  const isEpisodeRedeemRequired = useAppSelector(state =>
    selectIsEpisodeRedeemRequired(state, episode.episodeId),
  );

  const { ref } = useInView({
    threshold: 1,
    triggerOnce: true,
    onChange: inView => {
      if (inView) {
        AnalyticsService.episodeImpression({
          episodeId: episode.episodeId,
          location: { ...referrer },
          referrer,
        });
      }
    },
  });

  const { toggleBookmark, isBookmarked } = useBookmarkManagement({
    episodeId: episode.episodeId,
    location: { ...referrer, button: EVENT_CONSTANTS.BUTTON.BOOKMARK },
    referrer,
  });

  const onPlayNow = () => {
    dispatch(
      playerThunks.episodePlayPressed({
        episodeId: episode.episodeId,
        location: { ...referrer, button: EVENT_CONSTANTS.BUTTON.PLAY_NOW },
        referrer,
      }),
    );
  };

  const onTrailer = () => {
    dispatch(
      playerThunks.chapterPlayPressed({
        activeChapter: {
          episodeId: episode.episodeId,
          chapterId: episode.trailerId,
        },
        location: { ...referrer, button: EVENT_CONSTANTS.BUTTON.PREVIEW },
        referrer,
      }),
    );
  };

  const onRedeem = () =>
    dispatch(
      userEpisodesThunks.initiateRedemption({ episodeId: episode.episodeId }),
    );

  const isEpisodeEthics = getIsEpisodeEthics({ episode, userRegion });

  return (
    <div ref={ref}>
      <EpisodeBannerComponent
        referrer={referrer}
        episode={episode}
        onPlayNow={onPlayNow}
        onTrailer={onTrailer}
        onRedeem={onRedeem}
        toggleBookmark={toggleBookmark}
        isBookmarked={isBookmarked}
        isEpisodeEthics={isEpisodeEthics}
        isEpisodePremium={!!(isFreeTrialUser && episode.isPremium)}
        isEpisodeRedeemRequired={isEpisodeRedeemRequired}
      />
    </div>
  );
};
