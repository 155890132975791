import { Box, Theme, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';

import { CheckmarkAnimation } from '@/components';
import { selectUserRegion, useFetchEpisodeQuery } from '@/store';

interface EpisodeCompletedProps {
  episodeId: string;
}

export const EpisodeCompleted = ({ episodeId }: EpisodeCompletedProps) => {
  const region = useSelector(selectUserRegion);
  const { data: episode } = useFetchEpisodeQuery({ episodeId, region });
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  if (!episode) return null;

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-block',
        mt: 2,
      }}>
      <Box
        sx={{
          width: ({ dimensions }) => dimensions.episodeCardCover.width,
          height: ({ dimensions }) => dimensions.episodeCardCover.height,
          borderRadius: 4,
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
        }}>
        <Box
          component="img"
          id="episode-card-img"
          src={episode.cover}
          alt="cover"
        />
      </Box>
      <CheckmarkAnimation
        style={{
          position: 'absolute',
          top: -32,
          right: isSmall ? -30 : -50,
        }}
      />
    </Box>
  );
};
