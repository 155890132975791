import { Backdrop, Box, CircularProgress, useMediaQuery } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query/react';
import * as R from 'ramda';
import { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import config from '@/config';
import { Routes, RoutesWithPlayer } from '@/constants';
import {
  AppErrorBoundary,
  AppModals,
  AppRouteWrapper,
  Player,
} from '@/containers';
import {
  AnalyticsService,
  initGrowthbook,
  initLogRocket,
  initSentry,
} from '@/services';
import {
  playerThunks,
  selectIsFsmPlayer,
  selectSessionUserId,
  useFetchPlanQuery,
  useFetchPlayerConfigQuery,
  useFetchPlayerSessionsQuery,
  useFetchUserEpisodesQuery,
  useFetchUserProfileQuery,
  useFetchUserRegionQuery,
  useFetchUserRegistrationStateQuery,
  useFetchUserTagsQuery,
  useLinkPlanToUserMutation,
} from '@/store';
import { SnackbarUtils } from '@/utils';

export const AppLoader = () => {
  const dispatch = useDispatch();
  const userId = useSelector(selectSessionUserId);

  const {
    data: registrationState,
    isLoading: isUserRegistrationStateLoading,
    error: userRegistrationStateError,
  } = useFetchUserRegistrationStateQuery(userId ? undefined : skipToken);

  const {
    data: profile,
    isLoading: isUserProfileLoading,
    error: fetchUserProfileError,
  } = useFetchUserProfileQuery(userId ? undefined : skipToken);

  const {
    data: plan,
    isLoading: isPlanLoading,
    error: planError,
  } = useFetchPlanQuery(userId ? undefined : skipToken);

  const {
    data: region,
    isLoading: isUserRegionLoading,
    error: userRegionError,
  } = useFetchUserRegionQuery(
    userId && registrationState ? undefined : skipToken,
  );

  const {
    isLoading: isUserTagsLoading,
    error: userTagsError,
    data: userTags,
  } = useFetchUserTagsQuery(
    userId && registrationState ? undefined : skipToken,
  );

  const {
    data: playerConfig,
    isLoading: isPlayerConfigLoading,
    error: playerConfigError,
  } = useFetchPlayerConfigQuery(
    userId && registrationState ? undefined : skipToken,
  );

  const isFsmPlayer = useSelector(selectIsFsmPlayer);

  // TODO discuss dispatching a thunk to deal with all app load data
  // and then using selectors for things like userEpisodes which we know are App loaded
  // and guaranteed to be there
  const { isLoading: isPlayerSessionsLoading, error: errorOld } =
    useFetchPlayerSessionsQuery(
      !userId || !isFsmPlayer ? skipToken : undefined,
    );
  const { isLoading: isLegacyUserEpisodesLoading, error: errorNew } =
    useFetchUserEpisodesQuery(
      !userId || isFsmPlayer !== false ? skipToken : undefined,
    );
  const isUserEpisodesLoading = isFsmPlayer
    ? isPlayerSessionsLoading
    : isLegacyUserEpisodesLoading;
  const userEpisodesError = isFsmPlayer ? errorNew : errorOld;

  const [linkPlanToUser] = useLinkPlanToUserMutation();

  const { pathname, state, search } = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const isPlayerRoute = useMemo(() => {
    return Object.values(RoutesWithPlayer).some(route =>
      matchPath(route, pathname),
    );
  }, [pathname]);

  const isAppInitialized =
    !userId ||
    [registrationState, region, isFsmPlayer, profile, plan, userTags].every(
      R.isNotNil,
    );

  const isAppLoading =
    isUserProfileLoading ||
    isUserEpisodesLoading ||
    isPlanLoading ||
    isUserRegistrationStateLoading ||
    isUserRegionLoading ||
    isUserTagsLoading ||
    isPlayerConfigLoading;

  const error =
    fetchUserProfileError ||
    planError ||
    userEpisodesError ||
    userRegistrationStateError ||
    userRegionError ||
    userTagsError ||
    playerConfigError;

  useEffect(() => {
    initSentry({ userId, profile, region, plan, registrationState });
  }, [userId, profile, region, plan, registrationState]);

  useEffect(() => {
    AnalyticsService.initialize({
      userId,
      userRegion: region,
      userProfile: profile,
      playerConfig,
      userTags,
      organizationName: plan?.organizationName,
    });
  }, [userId, playerConfig, region, profile, userTags, plan?.organizationName]);

  useEffect(() => {
    const queryParams = Object.fromEntries(new URLSearchParams(search));
    const { campaign_id: campaignId } = queryParams;
    AnalyticsService.pageLoad({ queryParams, campaignId });
  }, [search]);

  useEffect(() => {
    const referrer = state?.referrer;
    const [section, resourceId] = pathname?.split('/').slice(1) || [];

    if (referrer) {
      AnalyticsService.clickThrough({
        target: pathname,
        referrer,
        section,
        resourceId,
      });
    }

    const location = referrer || { page: section };
    AnalyticsService.pageView(location);
  }, [pathname, state]);

  useEffect(() => {
    initLogRocket({
      userId,
      profile,
      region,
    });
  }, [profile, region, userId]);

  useEffect(() => {
    if ([userId, profile, registrationState].some(R.isNil)) return;

    initGrowthbook({
      userId,
      userProfile: profile,
      plan,
      userRegion: region,
      userTags,
      registrationState,
    });
  }, [plan, profile, region, userId, userTags, registrationState]);

  useEffect(() => {
    const hideIntercom = isMobile || pathname === Routes.CPA_CANADA;
    window.Intercom('boot', {
      user_id: userId,
      email: profile?.email,
      app_id: config.INTERCOM_APP_ID,
      vertical_padding: 100,
      hide_default_launcher: hideIntercom,
    });
  }, [isMobile, pathname, profile?.email, userId]);

  useEffect(() => {
    const linkPlan = async props => {
      const { error } = await linkPlanToUser(props);
      if (error) {
        SnackbarUtils.error('Failed to accept invite. Please contact support.');
        return;
      }

      const url = new URL(window.location.href);
      url.searchParams.delete('inviteToken');
      window.history.replaceState({}, '', url);
    };

    const { inviteToken } = Object.fromEntries(new URLSearchParams(search));

    if (inviteToken && userId) {
      linkPlan({ inviteToken });
    }
  }, [dispatch, linkPlanToUser, userId, search]);

  useEffect(() => {
    if (!isAppInitialized) return;

    const searchParams = new URLSearchParams(search);

    const { playerAction, playerActionPayload } =
      Object.fromEntries(searchParams);

    if (
      playerAction &&
      ['chapterPlayPressed', 'episodePlayPressed'].includes(playerAction)
    ) {
      dispatch(playerThunks[playerAction](JSON.parse(playerActionPayload)));

      searchParams.delete('playerAction');
      searchParams.delete('playerActionPayload');

      navigate(
        {
          pathname,
          search: new URLSearchParams(searchParams).toString(),
        },
        { replace: true },
      );
    }
  }, [dispatch, navigate, search, pathname, isAppInitialized]);

  return (
    <Box sx={{ color: 'text.black' }}>
      {error ? (
        <AppErrorBoundary />
      ) : (
        <Fragment>
          <Backdrop sx={{ zIndex: 'player' }} open={isAppLoading}>
            <CircularProgress />
          </Backdrop>
          <AppRouteWrapper />
          {isPlayerRoute && <Player />}
          <AppModals />
        </Fragment>
      )}
    </Box>
  );
};
