import { createEntityAdapter } from '@reduxjs/toolkit';

import {
  fetchLearningPathRequest,
  fetchLearningPathsRequest,
} from '@/requests';
import { postgrestApi } from '@/store/postgrest-api';
import { LearningPath, Region } from '@/types';

import { transformLearningPath } from './learning-paths.utils';

export const learningPathsAdapter = createEntityAdapter({
  selectId: (learningPath: LearningPath) => learningPath.groupId,
});

export const learningPathsApi = postgrestApi.injectEndpoints({
  endpoints: builder => ({
    fetchLearningPaths: builder.query<LearningPath[], { region: Region }>({
      query: fetchLearningPathsRequest,
      transformResponse: (response: unknown[]) =>
        response.map(transformLearningPath),
    }),
    fetchLearningPath: builder.query<
      LearningPath | undefined,
      { region: Region; learningPathId: number }
    >({
      query: fetchLearningPathRequest,
      transformResponse: (response: unknown[]) =>
        response[0] ? transformLearningPath(response[0]) : undefined,
    }),
  }),
});

export const { useFetchLearningPathsQuery, useFetchLearningPathQuery } =
  learningPathsApi;
