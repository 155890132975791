import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { EpisodeStatus } from '@/constants';
import {
  selectUserRegion,
  uiActions,
  useFetchRecommendedEpisodesQuery,
  userEpisodesSelectors,
} from '@/store';
import { Referrer } from '@/types';

import { EpisodeCard } from '../EpisodeCard';

interface YourNextListenProps {
  referrer: Referrer;
}

export const YourNextListen = ({ referrer }: YourNextListenProps) => {
  const dispatch = useDispatch();
  const region = useSelector(selectUserRegion);
  const { data: recommendations } = useFetchRecommendedEpisodesQuery(region);
  const episodeStatuses = useSelector(userEpisodesSelectors.selectEntities);

  const nextEpisodes = recommendations?.filter(
    recommendation =>
      !episodeStatuses[recommendation.episodeId] ||
      episodeStatuses[recommendation.episodeId].status === EpisodeStatus.NONE,
  );

  if (!nextEpisodes?.length) return null;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography
        variant="h3"
        gutterBottom
        sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
        Your Next Listen
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexDirection: { sm: 'row', xs: 'column' },
          alignItems: 'center',
        }}>
        {nextEpisodes.slice(0, 2).map(e => (
          <EpisodeCard
            episodeId={e.episodeId}
            key={e.episodeId}
            adjustWidth={false}
            onLinkClick={() => dispatch(uiActions.closeActiveModal())}
            referrer={referrer}
          />
        ))}
      </Box>
    </Box>
  );
};
