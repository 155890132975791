import {
  Fullscreen,
  FullscreenExit,
  Pause,
  PlayArrow,
  Replay30,
  SkipNext,
  SkipPrevious,
} from '@mui/icons-material';
import { Box, CircularProgress, Hidden, IconButton } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import screenfull from 'screenfull';

import { PLAYER_REPLAY_30 } from '@/constants';
import { AnalyticsService, EVENT_CONSTANTS } from '@/services';
import {
  playerActions,
  playerThunks,
  selectActiveChapterId,
  selectActiveEpisodeId,
  selectIsPlayerBuffering,
  selectIsPlayerPlaying,
  selectIsPlayerSeeking,
  selectIsPlayerVideoFullscreen,
  selectPlayerProgress,
  selectPlayerSeekToPosition,
  selectUserRegion,
  useFetchChapterQuery,
  useFetchEpisodeChaptersQuery,
  useFetchEpisodeQuery,
} from '@/store';
import { convertMsToHHMMSS } from '@/utils';

import { ProgressBar } from '../../../ProgressBar';
import { VolumeSlider } from '../../../VolumeSlider';

const VISIBILITY_DURATION = 3500;

export const VideoControls = () => {
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const lastChapterId = useRef(null);
  const visibilityTimer = useRef(null);

  const activeEpisodeId = useSelector(selectActiveEpisodeId);
  const activeChapterId = useSelector(selectActiveChapterId);
  const isBuffering = useSelector(selectIsPlayerBuffering);
  const isPlaying = useSelector(selectIsPlayerPlaying);
  const isSeeking = useSelector(selectIsPlayerSeeking);
  const seekToPosition = useSelector(selectPlayerSeekToPosition);
  const progress = useSelector(selectPlayerProgress);
  const isVideoFullscreen = useSelector(selectIsPlayerVideoFullscreen);
  const region = useSelector(selectUserRegion);
  const location = { component: EVENT_CONSTANTS.COMPONENT.VIDEO_PLAYER };

  const { data: episode } = useFetchEpisodeQuery({
    episodeId: activeEpisodeId,
    region,
  });
  const { data: chapters } = useFetchEpisodeChaptersQuery({
    episodeId: activeEpisodeId,
    region,
  });
  const chapterPosition = chapters?.find(
    ({ chapterId }) => chapterId === activeChapterId,
  )?.chapterPosition;
  const nextChapter = chapters?.find(
    current => current.chapterPosition === chapterPosition + 1,
  );
  const previousChapter = chapters?.find(
    current => current.chapterPosition === chapterPosition - 1,
  );
  const isChapterTrailer = activeChapterId === episode?.trailerId;
  const { data: chapter } = useFetchChapterQuery({
    chapterId: activeChapterId,
    region,
  });
  const chapterDuration = chapter?.duration;

  const toggleFullscreen = () => {
    dispatch(playerActions.setVideoFullscreen(!isVideoFullscreen));
    AnalyticsService.videoPlayerFullScreen({
      chapterId: activeChapterId,
      episodeId: activeEpisodeId,
      isFullScreen: !isVideoFullscreen,
    });
  };

  const togglePlay = () => {
    dispatch(
      isPlaying ? playerThunks.pausePlayer() : playerThunks.playPlayer(),
    );

    if (chapter) {
      AnalyticsService.chapterPause({
        chapterId: activeChapterId,
        episode: activeEpisodeId,
        location: {
          ...location,
          button: EVENT_CONSTANTS.BUTTON.PLAY,
        },
        referrer: location,
        pausing: isPlaying,
      });
    }
  };

  const seekThirtySecondsBack = () => {
    dispatch(
      playerThunks.seekToPosition({ delta: PLAYER_REPLAY_30, location }),
    );
    AnalyticsService.trackPlayerRewind({
      chapter,
      episode,
      location: { ...location, button: EVENT_CONSTANTS.BUTTON.SEEK_THIRTY },
    });
  };

  const playNext = () => {
    if (nextChapter) {
      dispatch(
        playerThunks.chapterPlayPressed({
          activeChapter: {
            episodeId: nextChapter.episodeId,
            chapterId: nextChapter.chapterId,
          },
          location: { ...location, button: EVENT_CONSTANTS.BUTTON.SKIP_NEXT },
          referrer: location,
        }),
      );
    }
  };
  const playPrevious = () => {
    if (previousChapter) {
      dispatch(
        playerThunks.chapterPlayPressed({
          activeChapter: {
            episodeId: previousChapter.episodeId,
            chapterId: previousChapter.chapterId,
          },
          location: {
            ...location,
            button: EVENT_CONSTANTS.BUTTON.SKIP_PREVIOUS,
          },
          referrer: location,
        }),
      );
    }
  };

  const onMouseMove = useCallback(() => {
    if (visibilityTimer.current) {
      clearTimeout(visibilityTimer.current);
    }
    if (!visible) {
      setVisible(true);
    }
    visibilityTimer.current = setTimeout(
      () => setVisible(false),
      VISIBILITY_DURATION,
    );
  }, [visible]);

  const onMouseLeave = useCallback(() => {
    if (visibilityTimer.current) {
      clearTimeout(visibilityTimer.current);
    }
    if (visible) {
      setVisible(false);
    }
  }, [visible]);

  useEffect(
    () => () => {
      clearTimeout(visibilityTimer.current);
    },
    [],
  );

  useEffect(() => {
    if (
      activeChapterId !== lastChapterId.current &&
      !isBuffering &&
      isPlaying
    ) {
      lastChapterId.current = activeChapterId;
      onMouseMove();
    }
  }, [activeChapterId, isBuffering, isPlaying, onMouseMove]);

  const position = progress.position || seekToPosition;
  const formattedCurrentPosition = convertMsToHHMMSS(position * 1000);
  const formattedChapterDuration = convertMsToHHMMSS(chapterDuration * 1000);

  if (isBuffering) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%">
        <CircularProgress sx={styles.circularProgressIndicator} />
      </Box>
    );
  }

  return (
    <Box
      sx={[styles.overlay, visible && styles.overlayVisible]}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      onTouchStart={
        onMouseMove
      } /* required for touch devices since mouse events might not work */
    >
      <Box sx={styles.controlsContainer}>
        <Box sx={styles.controls}>
          <IconButton
            sx={styles.button}
            onClick={seekThirtySecondsBack}
            disabled={isSeeking}
            disableRipple={true}
            disableFocusRipple={true}>
            <Replay30 sx={styles.buttonIcon} />
          </IconButton>
          <Hidden xsDown>
            <IconButton
              sx={styles.button}
              onClick={playPrevious}
              disabled={isChapterTrailer || !previousChapter}
              disableRipple={true}
              disableFocusRipple={true}>
              <SkipPrevious sx={styles.buttonIcon} />
            </IconButton>
          </Hidden>
          <IconButton
            sx={styles.button}
            onClick={togglePlay}
            disabled={!activeChapterId}
            disableRipple={true}
            disableFocusRipple={true}>
            {isPlaying ? (
              <Pause sx={styles.buttonIcon} />
            ) : (
              <PlayArrow sx={styles.buttonIcon} />
            )}
          </IconButton>
          <Hidden xsDown>
            <IconButton
              sx={styles.button}
              onClick={playNext}
              disabled={isChapterTrailer || !nextChapter}
              disableRipple={true}
              disableFocusRipple={true}>
              <SkipNext sx={styles.buttonIcon} />
            </IconButton>
          </Hidden>
          {progress && (
            <Box sx={styles.progressBar}>
              <ProgressBar referrer={location} />
            </Box>
          )}
          {progress && (
            <Box sx={styles.progressDuration}>
              {formattedCurrentPosition} / {formattedChapterDuration}
            </Box>
          )}
          <Hidden mdDown>
            <Box sx={styles.volumeControls}>
              <VolumeSlider iconSize={26} referrer={location} />
            </Box>
          </Hidden>
          {screenfull.isEnabled && (
            <IconButton
              sx={styles.button}
              onClick={toggleFullscreen}
              disabled={!activeChapterId}
              disableRipple={true}
              disableFocusRipple={true}>
              {isVideoFullscreen ? (
                <FullscreenExit sx={styles.buttonIcon} />
              ) : (
                <Fullscreen sx={styles.buttonIcon} />
              )}
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  overlay: {
    cursor: 'auto',
    height: '100%',
    opacity: 0,
    transition: 'opacity 0.2s ease-in-out',
    width: '100%',
  },
  overlayVisible: {
    opacity: 1,
  },
  controlsContainer: {
    bottom: 0,
    position: 'absolute',
    width: '100%',
  },
  controls: theme => ({
    alignItems: 'center',
    backgroundColor: 'neutral35',
    borderRadius: theme.typography.pxToRem(5),
    display: 'flex',
    flexDirection: 'row',
    height: theme.typography.pxToRem(38),
    justifyContent: 'space-between',
    margin: theme.typography.pxToRem(20),
    opacity: 0.8,
    padding: `0 ${theme.typography.pxToRem(10)}`,
    [theme.breakpoints.down('sm')]: {
      margin: theme.typography.pxToRem(10),
    },
  }),
  button: {
    color: 'white',
    padding: theme => theme.typography.pxToRem(6),
  },
  buttonIcon: {
    fontSize: theme => theme.typography.pxToRem(26),
  },
  progressBar: {
    flex: 1,
    margin: theme => `0 ${theme.typography.pxToRem(10)}`,
  },
  progressDuration: {
    color: 'white',
    fontSize: theme => theme.typography.pxToRem(12),
    padding: theme => `0 ${theme.typography.pxToRem(6)}`,
  },
  volumeControls: {
    padding: theme => theme.typography.pxToRem(6),
  },
  circularProgressIndicator: {
    fontSize: theme => theme.typography.pxToRem(32),
    color: 'neutral35',
  },
};
