import { Box, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

const DEFAULT_BLUR_AREA_HEIGHT = 70;
const DEFAULT_TRUNCATED_CONTENT_HEIGHT = 360;
const DefaultExpandIcon = ({ isTruncated }: { isTruncated?: boolean }) => (
  <Typography variant="button2">
    {isTruncated ? '— See more —' : '— See less —'}
  </Typography>
);

export interface TruncatedBoxProps {
  children: React.ReactNode;
  truncatedContentHeight?: number;
  blurAreaHeight?: number;
  ExpandIcon?: React.FC<{ isTruncated?: boolean }>;
}

export const TruncatedBox = ({
  children,
  truncatedContentHeight = DEFAULT_TRUNCATED_CONTENT_HEIGHT,
  blurAreaHeight = DEFAULT_BLUR_AREA_HEIGHT,
  ExpandIcon = DefaultExpandIcon,
}: TruncatedBoxProps) => {
  const [isTruncated, setIsTruncated] = useState(true);
  const [contentHeight, setContentHeight] = useState<number>();
  const contentRef = useRef<HTMLElement>();

  const setContentHeightFromRef = () => {
    const { height } = contentRef.current?.getBoundingClientRect() || {};
    setContentHeight(height);
  };

  useEffect(() => {
    setContentHeightFromRef();
    window.addEventListener('resize', setContentHeightFromRef);
    return () => window.removeEventListener('resize', setContentHeightFromRef);
  }, []);

  const effectiveBlurAreaHeight = isTruncated
    ? blurAreaHeight
    : blurAreaHeight / 1.5;

  if (!contentHeight || contentHeight <= truncatedContentHeight)
    return <Box ref={contentRef}>{children}</Box>;

  return (
    <Box
      sx={{
        height: isTruncated
          ? truncatedContentHeight
          : contentHeight + effectiveBlurAreaHeight,
        overflow: 'hidden',
        position: 'relative',
        transition: 'height 0.5s',
      }}>
      <Box
        onClick={() => setIsTruncated(!isTruncated)}
        sx={{
          position: 'absolute',
          bottom: 0,
          height: effectiveBlurAreaHeight,
          width: '100%',
          color: 'blue30',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 2,
          background:
            'linear-gradient(0deg, #FFF 0%, rgba(255, 255, 255, 0.90) 21%, rgba(255, 255, 255, 0.80) 52.5%, rgba(255, 255, 255, 0.00) 100%)',
        }}>
        <ExpandIcon isTruncated={isTruncated} />
      </Box>
      <Box ref={contentRef}>{children}</Box>
    </Box>
  );
};
