import { createSvgIcon } from '@mui/material/utils';

export const DiscoverIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_151_6427" fill="currentColor">
      <path d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" />
    </mask>
    <path
      d="M20 12C20 16.4183 16.4183 20 12 20V24C18.6274 24 24 18.6274 24 12H20ZM12 20C7.58172 20 4 16.4183 4 12H0C0 18.6274 5.37258 24 12 24V20ZM4 12C4 7.58172 7.58172 4 12 4V0C5.37258 0 0 5.37258 0 12H4ZM12 4C16.4183 4 20 7.58172 20 12H24C24 5.37258 18.6274 0 12 0V4Z"
      fill="currentColor"
      mask="url(#path-1-inside-1_151_6427)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4431 6.89493L11.2443 9.25806C10.3639 9.65825 9.65831 10.3639 9.25811 11.2442L6.89491 16.4431C6.70373 16.8637 7.1364 17.2963 7.55699 17.1052L12.7558 14.7421C13.6363 14.3419 14.342 13.6363 14.7422 12.7558L17.1051 7.557C17.2963 7.13641 16.8636 6.70375 16.4431 6.89493ZM11.2152 12.7369C11.6057 13.1275 12.2389 13.1275 12.6294 12.7369C13.0199 12.3464 13.0199 11.7132 12.6294 11.3227C12.2389 10.9322 11.6057 10.9322 11.2152 11.3227C10.8247 11.7132 10.8247 12.3464 11.2152 12.7369Z"
      fill="currentColor"
    />
  </svg>,
  'Discover',
);
