import config from '@/config';

const bffURL = 'https://ipa.lumiqlearn.com/bff';
const actionsURL = 'https://actions.lumiqlearn.com';

const getBaseUrl = () => window.location.origin;

export const urls = {
  base: getBaseUrl(),
  appBase: config.APP_ROOT || 'https://app.lumiqlearn.com',
  lumiq: 'https://lumiqlearn.com',
  faq: 'https://lumiqlearn.com/ca/faq',
  faqUS: 'https://www.lumiqlearn.com/en-us/faq',
  lumiqPrivacy: 'https://lumiqlearn.com/privacy-policy',
  lumiqTC: 'https://lumiqlearn.com/terms',
  social: {
    facebook: 'https://facebook.com/lumiqlearn',
    instagram: 'https://instagram.com/lumiqlearn',
    linkedin: 'https://linkedin.com/company/lumiqlearn',
    x: 'https://twitter.com/lumiqlearn',
  },
  auth: {
    google: `${bffURL}/auth/google`,
    apple: `${bffURL}/auth/apple`,
    workos: `${bffURL}/auth/workos/callback`,
  },
  accounts: {
    root: 'https://accounts.lumiqlearn.com',
  },
  actions: {
    root: actionsURL,
    logrocket: `${actionsURL}/logrocket/api/i`,
    sentry: `${actionsURL}/sentry`,
    mixpanel: `${actionsURL}/mixpanel/api`,
    growthbook: 'https://cdn.growthbook.io',
  },
  whatsLumiq: 'https://www.lumiqlearn.com/',
  supportEmail: 'help@lumiqlearn.com',
  ctcPage:
    'https://www.canada.ca/en/revenue-agency/programs/about-canada-revenue-agency-cra/federal-government-budgets/budget-2019-investing-middle-class/canada-training-credit.html',
  downloadApp: 'https://lumiq.app.link/tWrOXwdEinb',
};
