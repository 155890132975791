import { Close } from '@mui/icons-material';
import { Box, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';

export const FormContainer = ({
  children,
  sx,
  onModalClose,
}: {
  children?: ReactNode;
  sx?: SxProps<Theme>;
  onModalClose?: () => void;
}) => {
  return (
    <Box
      sx={[
        {
          backgroundColor: 'white',
          p: 6,
          borderRadius: 4,
          boxShadow: '0px 14px 19px 0px rgba(0, 0, 0, 0.25)',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 460,
          width: '100%',
          position: 'relative',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}>
      {onModalClose && (
        <Close
          onClick={onModalClose}
          sx={{
            position: 'absolute',
            top: 24,
            right: 24,
            fontSize: 32,
            color: 'blueBlack',
            cursor: 'pointer',
          }}
        />
      )}
      {children}
    </Box>
  );
};
