import { Box, CircularProgress, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@/components';
import { Routes } from '@/constants';
import { useGetInviteDetailsQuery, useGetSSORedirectUriQuery } from '@/store';

import { FormContainer, PageContainer, TextField } from '../components';

export const AcceptInvitePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { inviteToken, redirectUrl } = Object.fromEntries(
    new URLSearchParams(location.search),
  );
  const { data: inviteDetails, isLoading: isInviteDetailsLoading } =
    useGetInviteDetailsQuery(
      {
        inviteToken: inviteToken || '',
      },
      {
        skip: !inviteToken,
      },
    );

  const {
    data: ssoRedirectUri,
    isLoading: isRedirectUriLoading,
    isUninitialized,
  } = useGetSSORedirectUriQuery(
    {
      emailDomain: inviteDetails?.email?.split('@')[1] || '',
      redirect: redirectUrl || `${window.location.origin}${Routes.DISCOVER}`,
    },
    {
      skip: !inviteDetails?.email,
    },
  );

  useEffect(() => {
    if (!ssoRedirectUri && !isUninitialized && !isRedirectUriLoading) {
      navigate({
        pathname: Routes.REGISTER,
        search: new URLSearchParams(location.search).toString(),
      });
    }
  }, [
    ssoRedirectUri,
    navigate,
    location.search,
    isUninitialized,
    isRedirectUriLoading,
  ]);

  const isLoading = isInviteDetailsLoading || isRedirectUriLoading;

  return (
    <PageContainer>
      <FormContainer
        sx={{
          width: 460,
          gap: 1,
        }}>
        {isLoading ? (
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography variant="h1">Welcome to LumiQ</Typography>
            <Typography variant="h4" sx={{ pb: 6 }}>
              Join your colleaguers at {inviteDetails?.organizationName}
            </Typography>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              value={inviteDetails?.email}
              disabled
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: theme => theme.palette.text.black,
                },
              }}
            />
            <Button
              variant="primary"
              fullWidth
              sx={{
                height: 48,
                fontSize: 16,
                mt: 2,
              }}
              label="Continue"
              onClick={() => {
                if (ssoRedirectUri) {
                  window.location.href = ssoRedirectUri;
                } else {
                  navigate({
                    pathname: Routes.REGISTER,
                    search: new URLSearchParams(location.search).toString(),
                  });
                }
              }}
            />
          </>
        )}
      </FormContainer>
    </PageContainer>
  );
};
