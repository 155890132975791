import { Close } from '@mui/icons-material';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@/components';
import { Modals, urls } from '@/constants';
import { AppModal } from '@/containers';
import { AnalyticsService, CPA_BUTTON_NAME } from '@/services';
import { selectSessionUserId, uiActions } from '@/store';

export const CPASignUpModal = ({ cpaCanadaContent }) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  const isAnonymous = !useSelector(selectSessionUserId);

  const onCloseModal = () => {
    AnalyticsService.closeModal(
      isAnonymous ? Modals.CPA_SIGNUP : Modals.CPA_LISTEN_VERIFIABLE,
    );
    dispatch(uiActions.closeActiveModal());
  };

  if (!cpaCanadaContent) return null;

  return (
    <AppModal
      modalName={Modals.CPA_SIGNUP}
      fullScreen={isMobile}
      disableBackdropClick={true}>
      <Box
        sx={{
          border: 1,
          flexGrow: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          px: { xs: 2, md: 6 },
          py: 6,
        }}>
        <Button
          fullWidth={false}
          startIcon={<Close />}
          skinny={false}
          variant="tertiary"
          onClick={onCloseModal}
          sx={{ position: 'absolute', top: 4, left: 4 }}
        />
        <Box>
          <Typography variant="h3" mb={4}>
            {isAnonymous
              ? cpaCanadaContent.modalEpStart.header
              : cpaCanadaContent.modalLoggedIn.header}
          </Typography>
          <Typography variant="body1">
            {isAnonymous
              ? cpaCanadaContent.modalEpStart.subHeader
              : cpaCanadaContent.modalLoggedIn.subHeader}
          </Typography>
          <Button
            label={
              isAnonymous
                ? cpaCanadaContent.modalEpStart.ctaBtnText
                : cpaCanadaContent.modalLoggedIn.ctaBtnText
            }
            skinny={false}
            sx={{ mt: 4, mb: 1 }}
            onClick={() => {
              AnalyticsService.buttonClick(
                isAnonymous
                  ? { name: CPA_BUTTON_NAME.SIGNUP_MODAL }
                  : { name: CPA_BUTTON_NAME.CONTENT_VERIFIABLE_MODAL },
              );

              if (isAnonymous) {
                window.open(
                  urls.accounts.root + cpaCanadaContent.modalEpStart.ctaLink,
                  '_self',
                );
              } else {
                window.open(cpaCanadaContent.modalLoggedIn.ctaLink, '_self');
              }
            }}
          />
          {isAnonymous && (
            <Box>
              <Typography variant="caption">
                {cpaCanadaContent.modalEpStart.footer}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </AppModal>
  );
};
