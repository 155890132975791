import { createSvgIcon } from '@mui/material/utils';

export const MembersIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="7" r="3" stroke="currentColor" strokeWidth="2" />
    <circle cx="7" cy="6" r="2.075" stroke="currentColor" strokeWidth="1.85" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2599 13.0132C9.37153 12.2861 8.23586 11.85 6.99947 11.85C4.80882 11.85 2.93435 13.2192 2.19116 15.15H8.28058C7.8902 15.7249 7.55883 16.3448 7.29532 17H1.60941C0.720268 17 -0.0196443 16.2695 0.182339 15.4036C0.904703 12.3068 3.6827 10 6.99947 10C8.89565 10 10.6157 10.7539 11.8763 11.9784C11.302 12.2675 10.7609 12.6151 10.2599 13.0132Z"
      fill="currentColor"
    />
    <path
      d="M22.957 18.0306C23.0439 18.2735 22.9935 18.4867 22.8491 18.6641C22.6926 18.8563 22.423 19 22.1063 19H9.89372C9.57696 19 9.30744 18.8563 9.15092 18.6641C9.00646 18.4867 8.95608 18.2735 9.04295 18.0306C10.1008 15.0735 12.8321 13 16 13C19.1679 13 21.8992 15.0735 22.957 18.0306Z"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M23.4278 17.8622C23.7127 18.6587 23.074 19.5 22.1063 19.5H9.89372C8.92595 19.5 8.28727 18.6587 8.57217 17.8622C9.69572 14.7214 12.6052 12.5 16 12.5C19.3948 12.5 22.3043 14.7214 23.4278 17.8622Z"
      stroke="currentColor"
    />
  </svg>,
  'Members',
);
