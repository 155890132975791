import { createSvgIcon } from '@mui/material/utils';

export const EpisodeCountIcon = createSvgIcon(
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2.5C2 1.67157 2.67157 1 3.5 1H9.5C10.3284 1 11 1.67157 11 2.5V8.5C11 9.32843 10.3284 10 9.5 10H3.5C2.67157 10 2 9.32843 2 8.5V2.5ZM5 3.25L8.375 5.5L5 7.75V3.25ZM0 4.5C0 3.84689 0.417404 3.29127 1 3.08535V9.5C1 10.3284 1.67157 11 2.5 11H8.91465C8.70873 11.5826 8.15311 12 7.5 12H1.5C0.671573 12 0 11.3284 0 10.5V4.5Z"
      fill="currentColor"
    />
  </svg>,
  'EpisodeCount',
);
