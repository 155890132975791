import { createSvgIcon } from '@mui/material/utils';

export const TrackerActiveIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM17.2328 9.18045C17.6086 8.77574 17.5852 8.14301 17.1805 7.76721C16.7757 7.3914 16.143 7.41484 15.7672 7.81955L10.1013 13.9213L8.31373 11.4188C7.99272 10.9693 7.36817 10.8653 6.91876 11.1863C6.46935 11.5073 6.36526 12.1318 6.68627 12.5812L9.18627 16.0812C9.36089 16.3257 9.63617 16.4787 9.93598 16.4979C10.2358 16.5172 10.5284 16.4006 10.7328 16.1805L17.2328 9.18045Z"
      fill="currentColor"
    />
  </svg>,
  'TrackerActive',
);
