import Lottie, { LottieComponentProps } from 'lottie-react';
import { useEffect } from 'react';

import { checkmarkAnimation, correctSound } from '@/assets';

const sound = new Audio(correctSound);

export const CheckmarkAnimation = (
  props: Partial<LottieComponentProps> = {},
) => {
  useEffect(() => {
    sound.play();
  }, []);

  return <Lottie animationData={checkmarkAnimation} loop={false} {...props} />;
};
