import { Menu } from '@mui/icons-material';
import { AppBar, Box, Hidden, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { uiActions } from '@/store';

import {
  NavbarActions,
  NavbarLogo,
  NavbarPricing,
  NavBarRemainingUsage,
  RegionBanner,
  RemainingUsageBanner,
  SearchBar,
} from './components';

const SHADOW_OPACITY_ZONE = 200;

export const AppHeader = () => {
  const dispatch = useDispatch();

  const toggleSidebarDrawer = () => {
    dispatch(uiActions.toggleSidebarDrawer());
  };

  const [shadowOpacity, setShadowOpacity] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const newShadowOpacity = Math.min(
        1,
        window.scrollY / SHADOW_OPACITY_ZONE,
      );
      if (shadowOpacity !== newShadowOpacity)
        setShadowOpacity(newShadowOpacity);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [shadowOpacity]);

  return (
    <AppBar position="fixed" sx={styles.appbar} color="transparent">
      <RegionBanner />
      <RemainingUsageBanner />
      <Box sx={styles.root}>
        <Hidden lgDown>
          <Box sx={styles.logo}>
            <NavbarLogo />
          </Box>
        </Hidden>
        <Box sx={styles.header}>
          <Hidden lgUp>
            <IconButton
              sx={{ color: 'text.white', ml: 1 }}
              aria-label="open side drawer"
              onClick={toggleSidebarDrawer}>
              <Menu />
            </IconButton>
            <NavbarLogo />
          </Hidden>
          <Box sx={styles.rightContainer}>
            <SearchBar />
            <NavBarRemainingUsage />
            <NavbarPricing />
            <NavbarActions />
          </Box>
          <Box sx={[styles.headerShadow, { opacity: shadowOpacity }]} />
        </Box>
      </Box>
    </AppBar>
  );
};

const styles = {
  appbar: {
    boxShadow: 'none',
  },
  root: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  logo: {
    display: 'flex',
    alignItems: 'center',
    width: ({ dimensions }) => dimensions.sidebar.width + 'px',
    height: ({ dimensions }) => dimensions.header.height,
    background: ({ gradients }) => gradients.sidebar,
    flexShrink: 0,
    flexGrow: 0,
  },
  header: {
    width: '100%',
    height: ({ dimensions }) => dimensions.header.height,
    background: ({ gradients }) => ({
      xs: gradients.primary,
      lg: 'white',
    }),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerShadow: {
    position: 'absolute',
    bottom: 0,
    boxShadow: ({ customShadows }) => customShadows.header,
    width: '100%',
    height: 4,
    zIndex: -1,
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    ml: 'auto',
    mr: 3,
    gap: 2,
  },
};
