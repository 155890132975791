import {
  CPDPlayerListenRejectedReasons,
  CPEPlayerListenRejectedReasons,
  ListenRejectedReason,
  Modals,
} from '@/constants';
import { Modal } from '@/types';

export const isChapterCompleted = ({
  currentTime,
  duration,
}: {
  currentTime: number;
  duration: number;
}) => {
  return duration - currentTime < 30;
};

export const getListenRejectModalParams = ({
  reason,
  episodeId,
}: {
  reason:
    | (typeof ListenRejectedReason)[keyof typeof ListenRejectedReason]
    | (typeof CPDPlayerListenRejectedReasons)[keyof typeof CPDPlayerListenRejectedReasons]
    | (typeof CPEPlayerListenRejectedReasons)[keyof typeof CPEPlayerListenRejectedReasons];
  episodeId: string;
}): Modal => {
  switch (reason) {
    case ListenRejectedReason.NEED_REDEEM:
    case CPEPlayerListenRejectedReasons.NEED_REDEEM:
      return {
        name: Modals.REDEEM,
        params: { episodeId },
      };
    case ListenRejectedReason.ETHICS_LOCKED:
    case CPDPlayerListenRejectedReasons.ETHICS_LOCKED:
    case CPEPlayerListenRejectedReasons.ETHICS_LOCKED:
      return {
        name: Modals.ETHICS_LOCKED,
      };
    case ListenRejectedReason.PREMIUM_LOCKED:
    case CPDPlayerListenRejectedReasons.PREMIUM_LOCKED:
    case CPEPlayerListenRejectedReasons.PREMIUM_LOCKED:
      return {
        name: Modals.PREMIUM_LOCKED,
      };
    case ListenRejectedReason.FREE_TRIAL_ENDED:
    case ListenRejectedReason.PLAN_EXPIRED:
    case ListenRejectedReason.UNKNOW_ACCESS_TYPE:
    case ListenRejectedReason.NO_ACCESS_TYPE:
    case CPDPlayerListenRejectedReasons.INVALID_LICENCE:
    case CPEPlayerListenRejectedReasons.INVALID_LICENCE:
    case CPDPlayerListenRejectedReasons.INVALID_PLAN_TYPE:
    case CPEPlayerListenRejectedReasons.INVALID_PLAN_TYPE:
      return {
        name: Modals.INVALID_PLAN,
        params: { reason },
      };
    case ListenRejectedReason.PLAN_USED_UP:
      return { name: Modals.PLAN_USED_UP };
    case ListenRejectedReason.NOT_LOGGED_IN:
      return { name: Modals.SIGN_UP };
    default:
      return { name: Modals.PLAN_USED_UP };
  }
};
